import React, { useState, useEffect, useRef} from 'react';
import appConfig from './AppConfig';
import logo from './785.svg';
//import { API } from 'aws-amplify'
import { generateClient } from 'aws-amplify/api';
import { post } from 'aws-amplify/api';
//import PaypalTime from "./PaypalTime";
import {
  Flex,
//  Heading,
//  Image,
//  View,
} from "@aws-amplify/ui-react";
//import { Line } from 'rc-progress';
import {  toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
//import PaypalSubs from "./PaypalSubs";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import EditSetting from "./editSetting";
//import { Amplify } from 'aws-amplify';
//import awsExports from './aws-exports';
//Amplify.configure(awsExports);
const client = generateClient();

//v1.8b

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}








function StatusTime(props) { 


  const [fact2, setFact2] = useState("");
  const [enshList, setEnshList] = useState(  [{"name":"Loading","gameSettings":[ {name:"Loading",playerHealthFactor:1} ], "userGroups":[ {name:"Loading"} ]     }]);
  const [palList, setPalList] = useState(  [{"name":"Loading"}]);
  const [hideENSH, setHideENSH] = React.useState(true);
  const [hidePAL, setHidePAL] = React.useState(true);
  const [open4, setOpen4] = React.useState(false);

  const [spinHide, setSpinHide] = React.useState(true);

  const handleClickToOpenOptions = () => {
   
if (config1==="") {fetchFact2()};
//alert(props.utype)
  if (props.utype === "ENSHROUDED") {
    //ENSH
      setOpen5(true);  //Stop fetching
      setOpen4(true);
      setHideENSH(false);
      setSpinHide(true);

    } else {
      //PAL and OTHER
      setOpen5(true); //Stop fetching
      setOpen4(true);
      setHidePAL(false);
      setSpinHide(true);
    //  console.log("PL: " + palList)
    }
   


  };

  const handleToCloseOptions = () => {

    if (props.utype === "ENSHROUDED") {
      //ENSH
      setOpen5(false); //Start fetching
      setOpen4(false);
      setHideENSH(true);
    } else {
      //PAL and OTHER
      setOpen5(false);  //Start fetching
      setOpen4(false);
      setHidePAL(true);
    }

    
  };
  
  const [open5, setOpen5] = React.useState(false);

  const handleToCancelOptions = () => {
    if (props.utype === "ENSHROUDED") {
      //ENSH
      setOpen5(false); //Start fetching
      setOpen4(false);
      setHideENSH(true);
    } else {
      //PAL and OTHER
      setOpen5(false);  //Start fetching
      setOpen4(false);
      setHidePAL(true);
    }

  };

const [state, setState] = useState("0");
const [state2, setState2] = useState(0);

const [counter, setCounter] = useState(0);
 
const [tic, setTic] = useState(0);

const [loadTic, setLoadTic] = useState(0);

function notify(note) {toast.success(note);};
  //function notify(note) {toast.info(note)};
  //function enotify(note) {toast.error(note)};
  //function enotify(note) {toast.warning(note)};
   
function stateChange(f) {
    setState(f);
    props.funct(f);
//    to set ustanding
};
    
 
function stateChange2(f) {
  setState2(f);
  props.functReload(f);
//    to set reload
};


 
function handleEdit(f,a,b,c,type) {
  setSpinHide(true);


  //alert("EDIT "+props.utype);

  
  if (props.utype === "ENSHROUDED") { 
    //Ensh Edit
    const newList = enshList;

  //alert(f);
  //alert(a);
  //alert(b);

  //Error check new value
  if (type === "bol" ) {
    //boolean
    if (f === 'true' || f === 'false') {
      //Value ok'
     } else {
      //Bad value, force false
      f = 'false'
     }

  } else if (type === "num" ) {
    //number
    if (isNaN(f)) {
      //Not a number. force zero
        f = 0
       } else {  
        //Value OK
       }
  } else {
    //string (strip non alhanumeric)
    f.replace(/\W/g, '')
  }
    
  //Apply Change
  if (a === 'gameSettings' ) {
    //GameSettings Change
    if (b === 0 ) {
      //playerHealthFactor
      newList[0].gameSettings[0].playerHealthFactor = f;
    } else if (b === 1 ) {
      //playerManaFactor
      newList[0].gameSettings[0].playerManaFactor = f;
    } else if (b === 2 ) {
      //playerStaminaFactor
      newList[0].gameSettings[0].playerStaminaFactor = f;
    } else if (b === 3 ) {
      //enableDurability
      newList[0].gameSettings[0].enableDurability = f;
    } else if (b === 4 ) {
      //enableStarvingDebuff
      newList[0].gameSettings[0].enableStarvingDebuff = f;
    } else if (b === 5 ) {
      //foodBuffDurationFactor
      newList[0].gameSettings[0].foodBuffDurationFactor = f;
    } else if (b === 6 ) {
      //fromHungerToStarving
      newList[0].gameSettings[0].fromHungerToStarving = f;
    } else if (b === 7 ) {
      //shroudTimeFactor
      newList[0].gameSettings[0].shroudTimeFactor = f;
    } else if (b === 8 ) {
      //tombstoneMode
      newList[0].gameSettings[0].tombstoneMode = f;
    } else if (b === 9 ) {
      //miningDamageFactor
      newList[0].gameSettings[0].miningDamageFactor = f;
    } else if (b === 10 ) {
      //plantGrowthSpeedFactor
      newList[0].gameSettings[0].plantGrowthSpeedFactor = f;
    } else if (b === 11 ) {
      //resourceDropStackAmountFactor
      newList[0].gameSettings[0].resourceDropStackAmountFactor = f;
    } else if (b === 12 ) {
      //factoryProductionSpeedFactor
      newList[0].gameSettings[0].factoryProductionSpeedFactor = f;
    } else if (b === 13 ) {
      //perkUpgradeRecyclingFactor
      newList[0].gameSettings[0].perkUpgradeRecyclingFactor = f;
    } else if (b === 14 ) {
      //perkCostFactor
      newList[0].gameSettings[0].perkCostFactor = f;
    } else if (b === 15 ) {
      //experienceCombatFactor
      newList[0].gameSettings[0].experienceCombatFactor = f;
    } else if (b === 16 ) {
      //experienceMiningFactor
      newList[0].gameSettings[0].experienceMiningFactor = f;
    } else if (b === 17 ) {
      //experienceExplorationQuestsFactor
      newList[0].gameSettings[0].experienceExplorationQuestsFactor = f;
    } else if (b === 18 ) {
      //randomSpawnerAmount
      newList[0].gameSettings[0].randomSpawnerAmount = f;
    } else if (b === 19 ) {
      //aggroPoolAmount
      newList[0].gameSettings[0].aggroPoolAmount = f;
    } else if (b === 20 ) {
      //enemyDamageFactor
      newList[0].gameSettings[0].enemyDamageFactor = f;
    } else if (b === 21 ) {
      //enemyHealthFactor
      newList[0].gameSettings[0].enemyHealthFactor = f;
    } else if (b === 22 ) {
      //enemyStaminaFactor
      newList[0].gameSettings[0].enemyStaminaFactor = f;
    } else if (b === 23 ) {
      //enemyPerceptionRangeFactor
      newList[0].gameSettings[0].enemyPerceptionRangeFactor = f;
    } else if (b === 24 ) {
      //bossDamageFactor
      newList[0].gameSettings[0].bossDamageFactor = f;
    } else if (b === 25 ) {
      //bossHealthFactor
      newList[0].gameSettings[0].bossHealthFactor = f;
    } else if (b === 26 ) {
      //threatBonus
      newList[0].gameSettings[0].threatBonus = f;
    } else if (b === 27 ) {
      //pacifyAllEnemies
      newList[0].gameSettings[0].pacifyAllEnemies = f;
    } else if (b === 28 ) {
      //dayTimeDuration
      newList[0].gameSettings[0].dayTimeDuration = f;
    } else if (b === 29 ) {
      //nightTimeDuration
      newList[0].gameSettings[0].nightTimeDuration = f;
    } 
  }  else if ( a === 'userGroups') { 
      //UserGroups Change
      if (b === 0 ) {
        //Admin Group
        if (c === 1 ) {
         //Password   
           newList[0].userGroups[0].password = f;
                   
        } else if (c === 2 ) {
          //canKickBan    
          newList[0].userGroups[0].canKickBan = f;
        
      } else if (c === 3 ) {
        //canAccessInventories    
        newList[0].userGroups[0].canAccessInventories = f;
      
    } else if (c === 4 ) {
      //canEditBase    
      newList[0].userGroups[0].canEditBase = f;  
    } else if (c === 5 ) {
      //canExtendBase    
      newList[0].userGroups[0].canExtendBase = f;
        } else if (c === 6 ) {
          //Reserved Slots    
          newList[0].userGroups[0].reservedSlots = f;
        }
      }
      if (b === 1 ) {
        //Friend Group
        if (c === 1 ) {
         //Friend PAssword   
           newList[0].userGroups[1].password = f;
          } else if (c === 2 ) {
            //canKickBan    
            newList[0].userGroups[1].canKickBan = f;
          
        } else if (c === 3 ) {
          //canAccessInventories    
          newList[0].userGroups[1].canAccessInventories = f;
        
      } else if (c === 4 ) {
        //canEditBase    
        newList[0].userGroups[1].canEditBase = f;  
      } else if (c === 5 ) {
        //canExtendBase    
        newList[0].userGroups[1].canExtendBase = f;
          } else if (c === 6 ) {
            //Reserved Slots    
            newList[0].userGroups[1].reservedSlots = f;
          }
      };
      if (b === 2 ) {
        //Guest Group
        if (c === 1 ) {
         //Guest PAssword   
           newList[0].userGroups[2].password = f;
          } else if (c === 2 ) {
            //canKickBan    
            newList[0].userGroups[2].canKickBan = f;
        } else if (c === 3 ) {
          //canAccessInventories    
          newList[0].userGroups[2].canAccessInventories = f;
      } else if (c === 4 ) {
        //canEditBase    
        newList[0].userGroups[2].canEditBase = f;  
      } else if (c === 5 ) {
        //canExtendBase    
        newList[0].userGroups[2].canExtendBase = f;
          } else if (c === 6 ) {
            //Reserved Slots    
            newList[0].userGroups[2].reservedSlots = f;
          }
      };
    }   else {
      //Main Change
      if (b === 0 ) {
        //Name
        newList[0].name = f;
      };
    };
  //alert(newList[0].name)
  setEnshList(newList);


  } else {



  //Pal and Other Edit
  const newList = palList;

  //Error check new value
  if (type === "bol" ) {
    //boolean
    if (f === 'true' || f === 'false') {
      //Value ok'
     } else {
      //Bad value, force false
      f = 'false'
     }

  } else if (type === "num" ) {
    //number
    if (isNaN(f)) {
      //Not a number. force zero
        f = 0
       } else {  
        //Value OK
       }
  } else {
    //string (strip non alhanumeric)
    f.replace(/\W/g, '')
  }
    

  //Apply Change
  if (a === 'main' ) {
  //GameSettings Change
   if (b === 0 ) {
     //AdminPassword
     newList[0].AdminPassword = f;
   } else if (b === 2 ) {
     //ServerName
     newList[0].ServerName = f;
   } else if (b === 3 ) { 
    //ServerDescription
    newList[0].ServerDescription = f;
   } else if (b === 4 ) { 
    //Difficulty
    newList[0].Difficulty = f;
   } else if (b === 5 ) { 
    //DayTimeSpeedRate
    newList[0].DayTimeSpeedRate = f;
   } else if (b === 6 ) { 
    //NightTimeSpeedRate
    newList[0].NightTimeSpeedRate = f;
   } else if (b === 7 ) { 
    //ExpRate
    newList[0].ExpRate = f;
   } else if (b === 8 ) { 
    //DeathPenalty
    newList[0].DeathPenalty = f;
   } else if (b === 9 ) { 
    //CoopPlayerMaxNum
    newList[0].CoopPlayerMaxNum = f;
   } else if (b === 10 ) { 
    //GuildPlayerMaxNum
    newList[0].GuildPlayerMaxNum = f;
   } else if (b === 11 ) { 
    //ServerPassword
    newList[0].ServerPassword = f;
   }

  };    
  setPalList(newList);
 }

};

function setListEnd() {
  setSpinHide(true);
};


const [uname, setUname] = useState("");
const [skipFact, setSkipFact] = useState("0");
const [loader, setLoader] = useState("");

const [config1, setConfig1] = useState("");
const [config2, setConfig2] = useState("");
const [config3, setConfig3] = useState("");
const [config4, setConfig4] = useState("");
const [config5, setConfig5] = useState("");
const [config6, setConfig6] = useState("");
const [config7, setConfig7] = useState("");
const [config8, setConfig8] = useState("");
const [config9, setConfig9] = useState("");
const [config10, setConfig10] = useState("");
const [config11, setConfig11] = useState("");
const [config12, setConfig12] = useState("");
const [config13, setConfig13] = useState("");

   const apiName = 'gscapi'; // replace this with your api name.
const path = '/items'; //replace this with the path you have configured on your API







  async function fetchFact2() {
    //alert('ff2 ' + props.value);

    if (props.openGS !== 0 && open5 === false) {
//alert(props.openGS)
    var raw = JSON.stringify({"uname": props.value,"act": "status_"+ appConfig.apiname, "dat": props.openGS });
  
    //console.log('propsOGS', props.openGS);

    try {
      const restOperation = post({
        apiName: 'gscapi',
        path: '/items',
        options: {
          body: raw,
          headers: {'Content-Type': 'application/json'}, 
           redirect: 'follow', 
          
        }
      });
  
  
      const { body } = await restOperation.response;
     const response = await body.text();
      console.log('ST1 POST call succeeded');
      //console.log(response);



      setFact2(response);

      if (props.reload === 1 ) {stateChange2(0);}
      
      
      tmpstr = response.substring( response.indexOf("|") + 1,response.lastIndexOf("~") );
      tmpustanding = tmpstr.split("!")[5];
   
      tmpuconfig = tmpstr.split("!")[11];
      tmpuclass = tmpstr.split("!")[12];
      var tmputype = tmpuclass.split("-")[0];
      //console.log('tmputype', tmputype);

 //     setConfigList( '{"settings":[' + tmpuconfig + ']}');
 //      console.log("configList:" + configList)
        //tmpuconfig = {name:"N",desc:"D"}
        //var tmpList = tmpuconfig.replaceAll(/\t/gi,'');
        //var tmpconfig2 = tmpuconfig.replaceAll(/\t/gi,'');
        //var tmpList = [{  tmpconfig2 }];
        //setCommentList(tmpList.json());
       

    //    const sampleData = JSON.stringify({
    //      name: "Irakli",
    //      messages: ["hello", "goodbye", "good day"],
    //      age: 24
    //    });
    //    console.log("sample:" + sampleData);
    //    const parsedData = JSON.parse(sampleData);
    //    console.log(parsedData);
    //    const parsedData2 = JSON.parse(tmpuconfig);
     //   console.log(parsedData2);
        //var tmpDat = JSON.parse( '{  "name":"N1", "desc":"D"  }' );

      if (tmputype === "ENSHROUDED") {  
        //ENSH config gile
        var tmpDat = JSON.parse( tmpuconfig.replaceAll(/\t/gi,'') );
        setEnshList([tmpDat]);
        
       } else {
        //PAL and OTHER config file
     //   var tmpVar = tmpuconfig.slice(0,-1);
        //var tmpDat = JSON.parse( "{" + tmpVar.replaceAll(/=/gi,':')   + "}" )  ;
        //var tmpDat = JSON.parse(  tmpuconfig.replaceAll(/=/gi,':')  )  ;
        var tmpDat = JSON.parse(  tmpuconfig  )  ;
        //console.log(tmpVar);
       // console.log(tmpDat);
        setPalList([ tmpDat ]);
        //console.log("PalDat: " + palList);
      }

    
 //console.log(commentList);


 //     if (open5 === false && tmpuconfig != "" && tmputype != "ENSHROUDED" ) {

     
//      var tmpDconfig1 = tmpuconfig.split(",")[0];
//      var tmpDconfig1b = tmpDconfig1.split("=")[1];
//      setConfig1(tmpDconfig1b.replaceAll('"',""));
//      
//      var tmpDconfig2 = tmpuconfig.split(",")[1];
//      setConfig2(tmpDconfig2.split("=")[1]);

 //     var tmpDconfig3 = tmpuconfig.split(",")[2];
 //     var tmpDconfig3b = tmpDconfig3.split("=")[1];
 //     setConfig3(tmpDconfig3b.replaceAll('"',""));

 //     var tmpDconfig4 = tmpuconfig.split(",")[3];
 //     var tmpDconfig4b = tmpDconfig4.split("=")[1];
 //     setConfig4(tmpDconfig4b.replaceAll('"',""));

//      var tmpDconfig5 = tmpuconfig.split(",")[4];
 //     setConfig5(tmpDconfig5.split("=")[1]);

   //   var tmpDconfig6 = tmpuconfig.split(",")[5];
  //    setConfig6(tmpDconfig6.split("=")[1]);

  //    var tmpDconfig7 = tmpuconfig.split(",")[6];
  //    setConfig7(tmpDconfig7.split("=")[1]);

  //    var tmpDconfig8 = tmpuconfig.split(",")[7];
  //    setConfig8(tmpDconfig8.split("=")[1]);

  //    var tmpDconfig9 = tmpuconfig.split(",")[8];
   //   setConfig9(tmpDconfig9.split("=")[1]);
      
 //     var tmpDconfig10 = tmpuconfig.split(",")[9];
 //     setConfig10(tmpDconfig10.split("=")[1]);
      
 //     var tmpDconfig11 = tmpuconfig.split(",")[10];
 //     setConfig11(tmpDconfig11.split("=")[1]);
      
 //     var tmpDconfig12 = tmpuconfig.split(",")[11];
 //     setConfig12(tmpDconfig12.split("=")[1]);
      
 //     var tmpDconfig13 = tmpuconfig.split(",")[12];
 //     var tmpDconfig13b = tmpDconfig13.split("=")[1];
  ///    setConfig13(tmpDconfig13b.replaceAll('"',""));
      
 //   };


 //     if (tmpustanding !== props.ustanding) {
 //      /////////////////////////////////////////// stateChange('disabled');setFact2('');setSkipFact('0');
 //     } else {

 //     }
//

    } catch (e) {
      console.log('ST1 POST call failed: ', e);
      //console.log('ST1 POST call failed');
    }
  

    
    }


    };




    
  
//Set fetch time
  var tmpcnt = 8000; //8 seconds fetch

  



  useInterval(() => {
   //setUname(props.value);

   if (props.value === "--") {
    //skip unmae & fetch set
  } else {

if (props.ustat === "--") {
//ERROR in APP.JS
//alert('skipped fetch')
} else {
  

  if (props.ustanding === 'disabled') {
//skip

//alert('skip')
  } else {

    //Skip fact for 2 calls
    if (skipFact === "0" ) {
      //skip once
      setSkipFact('1');
      //alert('skipped once')
    } else if (skipFact === '1') {
      //skip twice
      setSkipFact('2');
      //alert('skipped twice') 
    } else {
      fetchFact2(); 

      
    if (loader === "starting" ) {
      setLoadTic( loadTic + 1 )
    };

    if (loader === "stopping" ) {
      setLoadTic( loadTic + 1 )
    }

    if (loader === "backing up" ) {
      setLoadTic( loadTic + 1 )
    }

    if (loader === "resetting" ) {
      setLoadTic( loadTic + 1 )
    }

    if (loader === "restarting" ) {
      setLoadTic( loadTic + 1 )
    }


    if (loader === "restoring" ) {
      setLoadTic( loadTic + 1 )
    }


    };
  }

};

  };
    if (counter === 10) {
      setCounter(1);
      if (tic === 100000) { 
        setTic(1);
      } else {
        setTic(tic + 1);
      }
    } else {
      setCounter(counter + 1);
    }
  }, tmpcnt);




  const handleSubmitComment2 = (e) => {
    e.preventDefault();
   // alert(config1)
  };


  function startButton() {
    //alert('STR-B:' + props.value + '-' + appConfig.apiname );


    var raw = JSON.stringify({"uname": props.value,"act": "start_"+ appConfig.apiname});

    var myInit = {
      body: raw,
      headers: {
        'Content-Type': 'application/json'}, 
        redirect: 'follow',
    };
    client.post(apiName, path, myInit)
    .catch((error) => {
      alert(appConfig.appname + " START API ERROR: " + error.message + ' ' + error.response)   }  );
    };
  
  
  function stopButton() {//alert('STO-B');
    var raw = JSON.stringify({"uname": props.value,"act": "stop_"+ appConfig.apiname});
       var myInit = {
      body: raw,
      headers: {
        'Content-Type': 'application/json'}, 
        redirect: 'follow',
    };
    client.post(apiName, path, myInit)
    .catch((error) => {
      alert(appConfig.appname + " STOP API ERROR: " + error.message + ' ' + error.response) 
   // .then((response) => {setFact2(response)
    }
   );



  };
  


  

function goBlank() {};
 
  









function goDefault() {

 //  defaultButtonPal()
//alert("DEFAULT " +  props.utype);

var tmpSlots = '"' + props.uslots +  '"'
if (props.utype === "ENSHROUDED") { 
  //ENSH

  var tmp = {"name":"GSERVER.CLOUD Enshrouded Server","saveDirectory":"./savegame","logDirectory":"./logs","ip":"0.0.0.0","queryPort":15637,"slotCount":props.uslots,"gameSettingsPreset":"Default","gameSettings":[{"playerHealthFactor":1,"playerManaFactor":1,"playerStaminaFactor":1,"enableDurability":true,"enableStarvingDebuff":false,"foodBuffDurationFactor":1,"fromHungerToStarving":600000000000,"shroudTimeFactor":1,"tombstoneMode":"AddBackpackMaterials","miningDamageFactor":1,"plantGrowthSpeedFactor":1,"resourceDropStackAmountFactor":1,"factoryProductionSpeedFactor":1,"perkUpgradeRecyclingFactor":0.5,"perkCostFactor":1,"experienceCombatFactor":1,"experienceMiningFactor":1,"experienceExplorationQuestsFactor":1,"randomSpawnerAmount":"Normal","aggroPoolAmount":"Normal","enemyDamageFactor":1,"enemyHealthFactor":1,"enemyStaminaFactor":1,"enemyPerceptionRangeFactor":1,"bossDamageFactor":1,"bossHealthFactor":1,"threatBonus":1,"pacifyAllEnemies":false,"dayTimeDuration":1800000000000,"nightTimeDuration":720000000000}],"userGroups":[{"name":"Admin","password":"Admin999","canKickBan":true,"canAccessInventories":true,"canEditBase":true,"canExtendBase":true,"reservedSlots":0},{"name":"Friend","password":"Friend111","canKickBan":false,"canAccessInventories":true,"canEditBase":true,"canExtendBase":false,"reservedSlots":0},{"name":"Guest","password":"Guest123","canKickBan":false,"canAccessInventories":false,"canEditBase":false,"canExtendBase":false,"reservedSlots":0}]}
  const newList = [tmp];
  setEnshList(newList);
  setSpinHide(true);

} else { };
  //PAL and OTHER
  
var tmp = {"AdminPassword":"GSC1234","ServerPlayerMaxNum": tmpSlots ,"ServerName":"GSERVER.CLOUD PalWorld","ServerDescription":"A PalWorld Server","Difficulty":"None","DayTimeSpeedRate":"1.000000","NightTimeSpeedRate":"1.000000","ExpRate":"1.000000","PalCaptureRate":"1","DeathPenalty":"All","CoopPlayerMaxNum":"4","GuildPlayerMaxNum":"20","ServerPassword":""}
const newList = [tmp];
setPalList(newList);
setSpinHide(true);

};









function goReset() {

  const input = prompt("Enter 'yes' below to confirm DATA ERASE and GAME WORLD RESET");
  if (input === null) { 
   // alert("Reset Cancelled")
  } else if( input === 'yes' ) {
    setLoadTic(0);resetButton(); setLoader("resetting");
    notify('Reset Request Sent')
  }


};


async function resetButton() {
  var raw = JSON.stringify({"uname": props.value,"act": "reset_"+ appConfig.apiname, "dat": props.openGS});

try {
  const restOperation = post({
    apiName: 'gscapi',
    path: '/items',
    options: {
      body: raw,
      headers: {'Content-Type': 'application/json'}, 
       redirect: 'follow', 
      
    }
  });

  const { body } = await restOperation.response;
 const response = await body.text();

  console.log('ST-Restart POST call succeeded');
  //console.log(response);
  //alert('Restart Command Sent')
} catch (e) {
  console.log('POST call failed: ', e);
}


};




function goRestart() {
  setLoadTic(0);  restartButton() ; setLoader("restarting");
   
    notify('Restart Sent')

};

async function restartButton() {
  var raw = JSON.stringify({"uname": props.value,"act": "restart_"+ appConfig.apiname, "dat": props.openGS });
  //var raw = JSON.stringify({"uname": props.value,"act": "status_"+ appConfig.apiname, "dat": props.openGS });
//console.log( raw  )
try {
  const restOperation = post({
    apiName: 'gscapi',
    path: '/items',
    options: {
      body: raw,
      headers: {'Content-Type': 'application/json'}, 
       redirect: 'follow', 
      
    }
  });

  const { body } = await restOperation.response;
 const response = await body.text();

  console.log('ST-Restart POST call succeeded');
  //console.log(response);
  //alert('Restart Command Sent')
} catch (e) {
  console.log('POST call failed: ', e);
}


};


function goBackup() {
  setLoadTic(0);  backupButton(); setLoader("backing up");
   
    notify('Backup Request Sent')

};

async function backupButton() {
  var raw = JSON.stringify({"uname": props.value,"act": "backup_"+ appConfig.apiname, "dat": props.openGS});

try {
  const restOperation = post({
    apiName: 'gscapi',
    path: '/items',
    options: {
      body: raw,
      headers: {'Content-Type': 'application/json'}, 
       redirect: 'follow', 
      
    }
  });

  const { body } = await restOperation.response;
 const response = await body.text();

  console.log('ST-Restart POST call succeeded');
  //console.log(response);
  //alert('Restart Command Sent')
} catch (e) {
  console.log('POST call failed: ', e);
}


};


function goUpdate() {


 updateButton();
  handleToCloseOptions();
  notify('Update Sent')

};

async function updateButton() {

if (props.utype === "ENSHROUDED") {
  //ENSH
  var raw = JSON.stringify({"uname": props.value,"act": "update_"+ appConfig.apiname, "dat": props.openGS,  "dat2": JSON.stringify(enshList) });
} else {
//PAL and OTHER
var raw = JSON.stringify({"uname": props.value,"act": "update_"+ appConfig.apiname, "dat": props.openGS,  "dat2": JSON.stringify(palList) });
}


//var raw = JSON.stringify({"uname": props.value,"act": "update_"+ appConfig.apiname, "dat": props.openGS,  "dat2": 'AdminPassword="'+config1+'"||ServerPlayerMaxNum='+config2+'||ServerName="'+config3+'"||ServerDescription="'+config4+'"||Difficulty='+config5+'||DayTimeSpeedRate='+config6+'||NightTimeSpeedRate='+config7+'||ExpRate='+config8+'||PalCaptureRate='+config9+'||DeathPenalty='+config10+'||CoopPlayerMaxNum='+config11+'||GuildPlayerMaxNum='+config12+'||ServerPassword="'+config13+'"'});

try {
const restOperation = post({
  apiName: 'gscapi',
  path: '/items',
  options: {
    body: raw,
    headers: {'Content-Type': 'application/json'}, 
     redirect: 'follow', 
    
  }
});

const { body } = await restOperation.response;
const response = await body.text();

console.log('ST-Update POST call succeeded');
//console.log(response);



//alert('Restart Command Sent')
} catch (e) {
console.log('ST-Update POST call failed: ', e);
}


};




function goRestore() {



  if (fact2 === "") {
    var tmpulastbackup = props.ulastbackup;    
  } else {
     tmpstr = fact2.substring( fact2.indexOf("|") + 1,fact2.lastIndexOf("~") );
     tmpulastbackup = tmpstr.split("!")[6];
  };
  
  if (tmpulastbackup !== "None" ) {


  const input = prompt("Enter 'yes' below to confirm DATA ERASE and RESTORE GAME WORLD FROM BACKUP");
  if (input === null) { 
   
  } else if( input === 'yes' ) {
    setLoadTic(0);restoreButton(); setLoader("restoring");
    notify('Restore Request Sent')
  }


  } else {
    alert("No Previous Backup Found")
  } 


};



async function restoreButton() {
  var raw = JSON.stringify({"uname": props.value,"act": "loadgame_"+ appConfig.apiname, "dat": props.openGS});

try {
  const restOperation = post({
    apiName: 'gscapi',
    path: '/items',
    options: {
      body: raw,
      headers: {'Content-Type': 'application/json'}, 
       redirect: 'follow', 
      
    }
  });

  const { body } = await restOperation.response;
 const response = await body.text();

  console.log('ST-Restart POST call succeeded');
  //console.log(response);
  //alert('Restart Command Sent')
} catch (e) {
  console.log('POST call failed: ', e);
}


};




  




  if (fact2 === "" || props.reload === 1 ) {
//alert(props.reload)
    var tmpstr = ""

  } else {
    tmpstr = fact2.substring( fact2.indexOf("|") + 1,fact2.lastIndexOf("~") );
//alert("using ST Fact ")

  };


 
 var tmpUspin=false;
  

 
 var tmpBClass = 'BK-button';
 var tmpDClass = 'BK-button';
 

 var tmpDstatus = "";
 
 var tmpDbutton = "";
 var tmpUstatus = "";
 
 var tmputos = props.utos; 
 var tmputos2 = props.utos;
 var tmpustanding = props.ustanding;
 var tmpulastbackup = props.ulastbackup;

 var tmpureg = props.ureg;
 var tmpuconfig = "Loading=...,Loading=...,Loading=...,Loading=...,Loading=...,Loading=...,Loading=...,Loading=...,Loading=...,Loading=...,Loading=...,Loading=...,Loading=...,";
 var tmpuclass = props.uclass;
 var tmpuip = props.uip;
 var tmputype = props.utype;

 var tmpstat = props.ustat;
 var tmpstat2 = tmpstat;
 var tmpstate = props.ustate;
//var tmpbank = props.ubank;
var tmpdet = props.udet;
var tmpstate2 = tmpstate;

//var tmpupass = props.upass;
var tmpuready = props.uready
//var tmplaststart = props.laststart

if (tmputos === "--") {

} else 

{

if (tmpstr !== "") { 
//alert("not blank");


   tmpstat = tmpstr.split("!")[0];

  tmpstat2 = tmpstat;
  tmpuready = tmpstr.split("!")[1];
  
    tmpdet = tmpstr.split("!")[3];

    tmpstate = tmpstr.split("!")[4];
   tmpstate2 = tmpstate;

     tmputos = tmpstr.split("!")[7];
     tmpustanding = tmpstr.split("!")[5];
     tmpulastbackup = tmpstr.split("!")[6];
 

     tmpureg = tmpstr.split("!")[10];
     tmpuconfig = tmpstr.split("!")[11];
     tmpuclass = tmpstr.split("!")[12];
     tmpuip = tmpstr.split("!")[17];
     tmputype = tmpuclass.split("-"[0]);
     
    //var tmp = tmpstr.split("!<<<<")[1];
    //var tmpupass = tmp.substring( tmp.indexOf("<<<<") + 1,tmp.lastIndexOf(">>>>") );





  } else {
    //tmpstr was empty
  // alert("blank")
  };
    




if (tmpuconfig === "" || tmputype !== "PALWORLD") {
//skip

} else {

  var tmpDconfig1 = tmpuconfig.split(",")[0];
  var tmpDconfig2 = tmpuconfig.split(",")[1];
  var tmpDconfig3 = tmpuconfig.split(",")[2];
  var tmpDconfig4 = tmpuconfig.split(",")[3];
  var tmpDconfig5 = tmpuconfig.split(",")[4];
  var tmpDconfig6 = tmpuconfig.split(",")[5];
  var tmpDconfig7 = tmpuconfig.split(",")[6];
  var tmpDconfig8 = tmpuconfig.split(",")[7];
  var tmpDconfig9 = tmpuconfig.split(",")[8];
  var tmpDconfig10 = tmpuconfig.split(",")[9];
  var tmpDconfig11 = tmpuconfig.split(",")[10];
  var tmpDconfig12 = tmpuconfig.split(",")[11];
  var tmpDconfig13 = tmpuconfig.split(",")[12];



   var tmpDconfig1a = tmpDconfig1.split("=")[0];
   var tmpDconfig2a = tmpDconfig2.split("=")[0];
   var tmpDconfig3a = tmpDconfig3.split("=")[0];
   var tmpDconfig4a = tmpDconfig4.split("=")[0];
   var tmpDconfig5a = tmpDconfig5.split("=")[0];
   var tmpDconfig6a = tmpDconfig6.split("=")[0];
   var tmpDconfig7a = tmpDconfig7.split("=")[0];
   var tmpDconfig8a = tmpDconfig8.split("=")[0];
   var tmpDconfig9a = tmpDconfig9.split("=")[0];
   var tmpDconfig10a = tmpDconfig10.split("=")[0];
   var tmpDconfig11a = tmpDconfig11.split("=")[0];
   var tmpDconfig12a = tmpDconfig12.split("=")[0];
   var tmpDconfig13a = tmpDconfig13.split("=")[0];
   
   var tmpDconfig1b = config1;
   var tmpDconfig2b = config2;
   var tmpDconfig3b = config3; 
   var tmpDconfig4b = config4;
   var tmpDconfig5b = config5;
   var tmpDconfig6b = config6;
   var tmpDconfig7b = config7;
   var tmpDconfig8b = config8;
   var tmpDconfig9b = config9;
   var tmpDconfig10b = config10;
   var tmpDconfig11b = config11;
   var tmpDconfig12b = config12;
   var tmpDconfig13b = config13;
   
   
    }







    tmpDstatus = tmpstat
    







    if (tmpstat2 === "Start Failed" ) {
      //ERROR OVERRIDE

      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      
      //tmpUbank="";
  
      tmpDstatus="Start Error (" + tmpdet +  ")";
      //tmpUbank="Admin has been notified";
      
      tmpDbutton='ERROR' ;
      tmpUspin='false';

    } else if (tmpstat2 === "Stop Failed" ) {
        //ERROR OVERRIDE
  
        tmpBClass = 'PR-button';
        tmpUspin=true;
        tmpDClass = 'App-processing';
        
       // tmpUbank="";
   
        tmpDstatus="Stop Error (" + tmpdet +  ")";
        //tmpUbank="Admin has been notified";
        
        tmpDbutton='ERROR' ;
        tmpUspin='false';



    } else {

     // var tmpLoader = "";


    if (loader === "starting" ) {

   
    
      if (tmpstat2 === "Starting" || tmpstat2 === "Running") {
        //clear loader
        setLoader("");
       // tmpLoader = "" ;
        
        
   //loader override

      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      
      //tmpUbank=dispTime(tmpbank);
      
      tmpstate = "starting";
      //alert(tmpstat);
      tmpstat = "DATA1B";
      tmpDstatus="Starting";
    
      

     
      } else {
      
      //loader override

      
        if (loadTic >= 4) {
          //Output Loader Error

          tmpBClass = 'PR-button';
          tmpUspin=true;
          tmpDClass = 'App-processing';
          
          tmpstate = "starting";
          
          tmpstat = "DATA1AE";
          tmpDstatus="Error - Start Unprocessed";
          
        } else {

          tmpBClass = 'PR-button';
          tmpUspin=true;
          tmpDClass = 'App-processing';
          
          
          
          tmpstate = "starting";
          
          tmpstat = "DATA1A";
          tmpDstatus="Starting";
          
          
        }


      };
        
  
    } else if (loader === "stopping") {
  
      if (tmpstat2 === "Stopping" || tmpstat2 === "Stopped") {
        //clear loader
        setLoader("")
        //tmpLoader = "" ;

            //loader override

      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      
      
      
      tmpstate = "stopping";
      
      tmpstat = "DATA2B";
      tmpDstatus="Stopping";
      
      

      } else {

      //loader override


      if (loadTic >= 5) {
        //Output Loader Error

        tmpBClass = 'PR-button';
        tmpUspin=true;
        tmpDClass = 'App-processing';
      
        tmpstate = "stopping";
      
        tmpstat = "DATA2AE";
        tmpDstatus="Error - Stop Unprocessed";
      
      } else {

      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      
      
      
      tmpstate = "stopping";
      //alert(tmpstat);
      tmpstat = "DATA2A";
     tmpDstatus="Stopping";
      };
      
      };
  
     } else {

      if (loader === "resetting") { 
        //loader ovveride cooldown
        tmpstat = "DATA2C";
        tmpstate = "resetting";

        if (loadTic >= 3) {
          if (tmpdet === "Reset" || tmpdet === "Restart"  || tmpdet === "Update"  || tmpdet === "") {
            //clear loader
            setLoader("")
           // tmpLoader = ""
           } else {
            if (loadTic >= 5) {
              tmpDstatus="Error - Reset Unprocessed";
            }
           }
          }
      };


      if (loader === "backing up") { 
        //loader ovveride cooldown
        tmpstat = "DATA2D";
        tmpstate = "backing up";

        if (loadTic >= 3) {
        if (tmpdet === "Backup" || tmpdet === "Restart"  || tmpdet === "Update"  || tmpdet === "") {
          //clear loader
          setLoader("")
         // tmpLoader = ""
         } else {
          if (loadTic >= 5) {
            tmpDstatus="Error - Backup Unprocessed";
          }
         }
        }

      };

     if (loader === "restoring") { 
        //loader ovveride cooldown
        tmpstat = "DATA2E";
        tmpstate = "restoring";

         if (loadTic >= 3) {
        if (tmpdet === "Restore" || tmpdet === "Restart" || tmpdet === "Update" || tmpdet === "") {
          //clear loader
          setLoader("")
         // tmpLoader = ""
         } else {
          if (loadTic >= 5) {
            tmpDstatus="Error - Restore Unprocessed";
          }
         }
        }
      };

      if (loader === "restarting") { 
        //loader ovveride cooldown
        tmpstat = "DATA2F";
        tmpstate = "restarting";
      // tmpBClass = 'PR-button';
       //tmpUspin=true;
       //tmpDClass = 'App-processing';
       tmpDbutton='WAITING';
       tmpDClass = 'App-processing';
       tmpBClass = 'PR-button';
 //     tmpUstatus=tmpstat; 
//      tmpDstatus= "Restarting" ; 
      tmpUspin =true;


      if (loadTic >= 2) {
        if (tmpdet === "Restart" || tmpdet === "Update" || tmpdet === "") {
          //clear loader
          setLoader("")
        //  tmpLoader = "" 
        } else {
          if (loadTic >= 5) {
            tmpDstatus="Error - Restart Unprocessed";
          }
         }
        }
      };



     };




/////////////////////////////////////////Main 




  if (tmpstate === "starting" ) {
    //Starting State 
    
    if (tmpstat === "Stopped" ) {
      // skip
      tmpDstatus ="ERROR";


    } else if (tmpstat === 'Running') {
      //Running Status


      if (tmpdet === "Restarting" ) {
        //Restaring - Running
        tmpDbutton='WAITING';
        tmpDClass = 'App-processing';
        tmpBClass = 'PR-button';
        tmpUstatus=tmpstat; 
        tmpDstatus=tmpdet; 
        tmpUspin =true;

      } else if (tmpdet === "Updating" ) {
          //Restaring - Running
          tmpDbutton='WAITING';
          tmpDClass = 'App-processing';
          tmpBClass = 'PR-button';
          tmpUstatus=tmpstat; 
          tmpDstatus=tmpdet; 
          tmpUspin =true;
  

        } else if (tmpdet === "Backup" ) {
          //Restaring - Running
          tmpDbutton='WAITING';
          tmpDClass = 'App-processing';
          tmpBClass = 'PR-button';
          tmpUstatus=tmpstat; 
          tmpDstatus='Backing Up'; 
          tmpUspin =true;
  


      } else if (tmpdet === "Config" ) {
         //Running, Needs config restart
        tmpDbutton='STOP' ;
        tmpDClass = 'App-running';
        tmpBClass = 'SP-button';
        tmpUstatus=tmpstat; 
        tmpDstatus= tmpstat + " (Restart Req) " ; 
        tmpUspin =false;

      } else if (tmpdet === "Restart" ) {
        //Running, Needs config restart
        tmpDbutton='WAITING';
        tmpDClass = 'App-processing';
        tmpBClass = 'PR-button';
       tmpUstatus=tmpstat; 
       tmpDstatus= "Restarting" ; 
       tmpUspin =true;


      } else if (tmpdet === "Update" ) {
        //Running, Needs config restart
        tmpDbutton='WAITING';
        tmpDClass = 'App-processing';
        tmpBClass = 'PR-button';
       tmpUstatus=tmpstat; 
       tmpDstatus= "Updating" ; 
       tmpUspin =true;


      } else if (tmpdet === "Recover" ) {
        //Running, Needs config restart
        tmpDbutton='WAITING';
        tmpDClass = 'App-processing';
        tmpBClass = 'PR-button';
       tmpUstatus=tmpstat; 
       tmpDstatus= "Recovering" ; 
       tmpUspin =true;

      } else if (tmpdet === "Create" ) {
        //Running, Needs config restart
        tmpDbutton='WAITING';
        tmpDClass = 'App-processing';
        tmpBClass = 'PR-button';
       tmpUstatus=tmpstat; 
       tmpDstatus= "Starting" ; 
       //tmpuclass= "Activating"
       tmpUspin =true;


      } else {
        //Normal Running
        tmpDbutton='STOP' ;
        tmpDClass = 'App-running';
        tmpBClass = 'SP-button';
        tmpUstatus=tmpstat; 
        tmpUspin =false;
      };


      if (tmpustanding === "blocked") {
        tmpDbutton='WAITING';
        tmpDClass = 'App-running';
        tmpBClass = 'PR-button';
       tmpUstatus=tmpstat; 
       tmpDstatus= "Account Blocked" ; 
       tmpUspin =false;


      }


  
    } else if (tmpstat === "") {
      // skip
      tmpDstatus ="ERROR - No State Data";
    } else {
  
      tmpDbutton='WAITING';
     
      tmpBClass = 'PR-button';
      
      tmpDClass = 'App-processing';

      tmpUstatus=tmpstat;

      tmpUspin=true;

    };

  } else if (tmpstate === "resetting") {
    
      tmpDbutton='WAITING';
      
      tmpBClass = 'PR-button';
      
      tmpDClass = 'App-processing';
      tmpUstatus=tmpstat; 
      tmpDstatus="Resetting"
      
      tmpUspin=true;
    } else if (tmpstate === "restarting") {
    
      tmpDbutton='WAITING';
      
      tmpBClass = 'PR-button';
      
      tmpDClass = 'App-processing';
      tmpUstatus=tmpstat; 
      tmpDstatus="Restarting"
      
      tmpUspin=true;


    } else if (tmpstate === "backing up") {
    
      tmpDbutton='WAITING';
      
      tmpBClass = 'PR-button';
      
      tmpDClass = 'App-processing';
      tmpUstatus=tmpstat; 
      tmpDstatus="Backing Up"
      
      tmpUspin=true;




    } else if (tmpstate === "restoring") {
    
      tmpDbutton='WAITING';
      
      tmpBClass = 'PR-button';
      
      tmpDClass = 'App-processing';
      tmpUstatus=tmpstat; 
      tmpDstatus="Restoring"
      
      tmpUspin=true;



  } else if (tmpstate === "stopping") {

    if (tmpstat === "Stopped" ) {


      if (tmputos2 === "1") {


   //     if (tmpustanding ==="enabled" && tmpureg !== "not set"  && tmpuready !== 'no' ) {
   if (tmpustanding ==="enabled" && tmpureg !== "not set"  ) {
   
        tmpDbutton='START' ;
       
        tmpDClass = 'App-processing';
        tmpBClass = 'PR-button';
     tmpDstatus= "Creating Server";
        tmpUstatus=tmpstat; 
    
        tmpUspin=false;
        //tmpUmessage="";
        
        
        //tmpUmessage=tmpitype
      } else if (tmpureg ==="not set") {
        //Region not set
        tmpDbutton='DISABLED';
       
        tmpBClass = 'PR-button';
       
        tmpDClass = 'App-processing';
        tmpUstatus=tmpstat; 
       
       tmpDstatus="Please select a Datacenter Location";
       
        tmpUspin=false;

        
   //   } else if (tmpuready === "no" ) {
  //      //NOT READY OVERRIDE
  //
  //      tmpBClass = 'PR-button';
   //     tmpUspin=true;
   //     tmpDClass = 'App-processing';
   //     
  //     // tmpUbank="";
  // 
   //     tmpDstatus="Creating Account";
   //     //tmpUbank="Admin has been notified";
   //     
   //     tmpDbutton='WAITING' ;
   //     tmpUspin='false';
        
       } else {





        
          tmpDbutton='DISABLED';
       
          tmpBClass = 'PR-button';
       
          tmpUstatus=tmpstat; 
       
            tmpUspin=false;
       
       tmpDClass = 'App-running';
            tmpDstatus="Please subscribe via PayPal to activate account";
     

        };
       } else if (tmputos2 ==="0") {
   
        tmpDbutton='DISABLED';
        
        tmpBClass = 'PR-button';
        
        tmpDClass = 'App-running';
        tmpUstatus=tmpstat; 
   
       tmpDstatus="Please accept the 'Terms Of Service' and 'Privacy Policy'";
   
        tmpUspin=false;
        

       } else {
        alert("ERROR - missing tos value (" + tmputos + ")")

      };

     } else if (tmpstat === 'Running') {
     // skip



      
     } else if (tmpstat === "") {
      // skip


      
     } else if (tmpstat === "Bad Account Name") { 
      alert('Bad Account ' + props.value + " " + tmpstat)

      tmpDstatus="Loading Account " + props.value + "/" + uname;


     } else if (tmpstat === "No Account Found") {




    
      tmpDbutton='DISABLED';
    
      tmpBClass = 'PR-button';
    
      tmpDClass = 'App-processing';
      tmpUstatus=tmpstat; 
    
      tmpUspin=false;
      tmpDstatus="No Account Found. Please request access"



     } else {

      tmpDbutton='WAITING';

      tmpBClass = 'PR-button';

      tmpDClass = 'App-processing';
      if (appConfig.burnOn === "true") {tmpDstatus="Destroying PC"};
      tmpUstatus=tmpstat;

      tmpUspin=true;

     };
  
   
   

  
  } else {
  //  alert("error")
  };


}


}






 
   //var tmpCol = '#FECD45'
   var tmpCol = 'white'

 


    return (


      
        <>
          
      

       <div style={{ display:'flex', flexDirection:'column', paddingTop:'30px', gap:'10px', color: '#e7e7e7' , textAlign: "center", fontWeight: 'bold' , fontSize: 'calc(5px + 1vw)', backgroundColor:'' }}> 
       
       {tmputos2 === "1"  ?     (


        tmpustanding ==="enabled" || tmpustanding === "blocked" ? (<>
          <span style={{ color: '#e7e7e7' , textAlign: "center", fontWeight: 'bold' , fontSize: '2.3vw' }} >{tmpuclass} </span><span className = {tmpDClass} style={{ textAlign: "center", fontWeight: 'bold' , fontSize: '2.3vw' }}  >{tmpDstatus}</span>
         
          {tmpdet === ""  && loader === "" ?     (
              <Flex direction="column" gap="0" paddingTop='.4vw'  style={{ color: "white" , textAlign: "center", fontWeight: 'bold' , fontSize: '1.3vw' }} > <span>Server Address: <span style={{color:'grey'}}>{tmpuip}</span></span>   <span   style={{ color: "white" , textAlign: "center", fontWeight: 'bold' , fontSize: '1.3vw' }} >Last Backup: <span style={{color:'grey'}}>{tmpulastbackup}</span></span>       <span><span   style={{ color: "white" , textAlign: "center", fontWeight: 'bold' , fontSize: '1.3vw' }} >Player Slots: <span style={{color:'grey'}}>{props.uslots}</span></span> <span   style={{ color: "white" , textAlign: "center", fontWeight: 'bold' , fontSize: '1.3vw' }} >  <button className='blankBtn' >  </button> Region: <span style={{color:'grey'}}>{props.ureg.split('-')[1]}</span> </span></span>       </Flex>   
          ) : (<> 
          
          {tmpdet === "Config" ? (<>
            <Flex direction="column" gap="0" paddingTop='.4vw'  style={{ color: "white" , textAlign: "center", fontWeight: 'bold' , fontSize: '1.3vw' }} > <span>Server Address: <span style={{color:'grey'}}>{tmpuip}</span></span>   <span   style={{ color: "white" , textAlign: "center", fontWeight: 'bold' , fontSize: '1.3vw' }} >Last Backup: <span style={{color:'grey'}}>{tmpulastbackup}</span></span>       <span><span   style={{ color: "white" , textAlign: "center", fontWeight: 'bold' , fontSize: '1.3vw' }} >Player Slots: <span style={{color:'grey'}}>{props.uslots}</span></span> <span   style={{ color: "white" , textAlign: "center", fontWeight: 'bold' , fontSize: '1.3vw' }} >  <button className='blankBtn' >  </button> Region: <span style={{color:'grey'}}>{props.ureg.split('-')[1]}</span> </span></span>       </Flex>   

          </>): (<><br></br><br></br> </>)}

           
          
           
           </>) }

        </>) : (<><br></br>
          <span style={{ color: '#FECD45' , textAlign: "center", fontWeight: 'bold' , fontSize: '2.3vw' }} >SERVER SETUP</span><br></br><br></br> <span className = {tmpDClass} style={{ textAlign: "center", fontWeight: 'bold' , fontSize: '2.3vw' }}  >{tmpDstatus}</span>

          
          
        </> )


       
        ) :(<><br></br>
        <span style={{ color: '#FECD45' , textAlign: "center", fontWeight: 'bold' , fontSize: '2.3vw' }} >SERVER SETUP</span><br></br><br></br> <span className = {tmpDClass} style={{ textAlign: "center", fontWeight: 'bold' , fontSize: '2vw' }}  >{tmpDstatus}</span>
       </>) }


</div> 
  
         



{ tmpUspin === true ? (<>
  <Flex 
      
    direction="column"
    paddingTop="1vw"
    
    minHeight="4vw"
    textAlign="center"
    alignItems="center"
    justifyContent= "center"
    >     
      
      <img src={logo} className="App-logo" alt="logo" />
      
      </Flex>
</>):(<>


  {tmpustanding ==="enabled"  ? (<>
  
    <Flex 
      
      direction="column"
      minHeight="0vh"
      paddingTop="4vw" //NOOOOOOO Change
      textAlign="center"
      alignItems="center"
      justifyContent= "center"
      backgroundColor=''
      >     
  
    </Flex>

  </>):(<>
  
    <Flex 
      
      direction="column"
      minHeight="0vh"
      paddingTop="0vw" //NOOOOOOO Change
      textAlign="center"
      alignItems="center"
      justifyContent= "center"
      >     
  
    </Flex>
  
  </>)}





</>)}


<Flex
         direction="column"
         textAlign="center"
         alignItems="center"
         fontSize= "calc(5px + 1vw)"
         gap="0px"
        backgroundColor=''

        >

      {tmputos2 === "1" ? (
      
      tmpustanding ==="enabled" ? (<>

<Flex
         direction="column"
         textAlign="center"
         alignItems="center"
         fontSize= "calc(5px + 1vw)"
         gap="0px"
         minHeight="15.5vw" ////////////////////////////////////////// <<<<<<<<<<<<<<<<
//backgroundColor='red'
        >

        
          <span style={{ paddingTop:'2vw', color: 'white' , textAlign: "center", fontWeight: 'bold' ,  fontSize: '2.3vw' }}  >SERVER CONTROLS</span>


          {  tmpDbutton === 'STOP' || tmpDbutton === "START"? (<></>) : (   <button className = {tmpBClass} style={{ border: "none", borderRadius: '30px' ,  display: "inline-block", marginTop: "1.2vw", fontWeight:'bold', fontSize: '1.3vw',  height: "auto", width: "10vw"  }} onClick={goBlank}>{tmpDbutton}</button>)
}



          {tmpUstatus === "Stopped" && tmpustanding ==="enabled" ? ( <> 
        { 
        //         <button className = 'Misc-button' style={{ border: "none", borderRadius: '30px', display: "inline-block", fontSize: '1.3vw', fontWeight: 'bold', marginTop: "1vw", height: "auto", width: "10vw" }}  onClick={goOpt1}>OPTIONS</button>
        }
        </> ) : (<>
        
        
          {tmpUstatus === "Running" && tmpdet !== "Restart" && tmpdet !== "Update"  && tmpdet !== "Recover" && tmpdet !== "Create" && tmpdet !== "Backup" ? ( <>  
  

<Flex
direction='row'
justifyContent='center'
columnGap='15px'
rowGap='0px'
wrap='wrap'
maxWidth='50vw'
//backgroundColor='green'
paddingBottom='30px'
>
        <button className = 'RDP-button' style={{ border: "none", borderRadius: '30px' ,  display: "inline-block", marginTop: "1.2vw", marginBottom: "1vw", fontWeight:'bold', fontSize: '1.3vw',  height: "auto", width: "10vw"  }} onClick={goRestart}>RESTART</button> 
        <button className = 'RDP-button' style={{ border: "none", borderRadius: '30px' ,  display: "inline-block", marginTop: "1.2vw", marginBottom: "1vw", fontWeight:'bold', fontSize: '1.3vw',  height: "auto", width: "10vw"  }} onClick={goBackup}>BACKUP</button> 
        <button className = 'RDP-button' style={{ border: "none", borderRadius: '30px' ,  display: "inline-block", marginTop: "1.2vw", marginBottom: "1vw", fontWeight:'bold', fontSize: '1.3vw',  height: "auto", width: "10vw"  }} onClick={goRestore}>RESTORE</button> 
        <button className = 'RDP-button' style={{ border: "none", borderRadius: '30px' ,  display: "inline-block", marginTop: "1.2vw", marginBottom: "1vw", fontWeight:'bold', fontSize: '1.3vw',  height: "auto", width: "10vw"  }} onClick={goReset}>RESET</button> 
        <button className = 'RDP-button' style={{ border: "none", borderRadius: '30px' ,  display: "inline-block", marginTop: "1vw", marginBottom: "1vw", fontWeight:'bold', fontSize: '1.3vw',  height: "auto", width: "10vw"  }} onClick={handleClickToOpenOptions}>OPTIONS</button> 
        </Flex>


  </>
    ) : (             <>       </>     )}
        
        </>)}

          </Flex>
        

        
          </>):(

            tmpustanding ==="blocked" ? (<> <br></br> <br></br></>):(<></>)

        
        
        )
      
      
      ):(<>
      
    
      
      </>) }
         


 
      { tmpUspin === 'disabled' ? (<><Flex 
      
      direction="column"
      //marginLeft='10px'
      //marginRight='10px'
     // wrap='wrap'
     // gap='10px'
 //     marginTop='15px'
  //    marginBottom='calc(15px + 6px)'
  paddingTop="15px"
  //paddingBottom="9px"
     textAlign="center"
     alignItems="center"
     justifyContent= "center"
    // gap = '0px'
     //minHeight={appConfig.minHeightD }
 
     >     
      
      <img src={logo} className="App-logo" alt="logo" />
      
      </Flex>
      

      
      
      </> ) : ( 
        
  
        tmputos2 === "1" ? (<>
        
         
  
        </>   ) : (<></>) 
  
  
        
         ) }

</Flex>











       
 
 <div stlye={{}}>


           
      <Dialog open={open4} onClose={handleToCloseOptions}>
        <DialogTitle style={{fontSize:'1.5vw' , textAlign:"center", backgroundColor:tmpCol}}>OPTIONS</DialogTitle>
        <DialogContent style={{backgroundColor:tmpCol}} >
          <DialogContentText>

          
                   

            <Flex 
          
          direction="column"
          textAlign="left"
          alignItems="flex-start"
          color="black"
          //maxWidth="25vw"
         // minWidth="50vw"
          marginLeft="1vw"
          marginRight="1vw"
          minHeight="30vh"
          gap="0"
   //   borderStyle='solid'
    //  borderWidth='1px'
      //borderRadius='30px'
         // paddingTop="1vw"
          fontSize="1vw"
      //    wrap="wrap"
      //    margin='10px'
          >

        
<span hidden = {hideENSH}>



<span hidden = {spinHide} /> 

<span style={{fontSize:'1.5vw' }} >Server Settings</span><br></br>

{enshList.map( (comment, index) => ( 

    <span key={index} > 
      Server Name : {comment.name} <EditSetting Name="Server Name" ValName="name" ValData={comment.name} Type="str" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"main"} Id2={0} Id3={0} /> 
<br></br><br></br>
<span style={{fontSize:'1.5vw'}} >Game Settings</span>

      {comment.gameSettings.map( (comment2, index2) => (    
          <span key={index2} > PlayerHealthFactor : {comment2.playerHealthFactor} <EditSetting Name="playerHealthFactor" ValData={comment2.playerHealthFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={0} Id3={0}  /> <br></br> 
            playerManaFactor : {comment2.playerManaFactor} <EditSetting Name="playerManaFactor" ValData={comment2.playerManaFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={1} Id3={0} /> <br></br>
            playerStaminaFactor : {comment2.playerStaminaFactor} <EditSetting Name="playerStaminaFactor" ValData={comment2.playerStaminaFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={2} Id3={0} /> <br></br>
            enableDurability : {String(comment2.enableDurability)} <EditSetting Name="enableDurability" ValData={comment2.enableDurability} Type="bol" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={3} Id3={0} /> <br></br>
            enableStarvingDebuff : {String(comment2.enableStarvingDebuff)} <EditSetting Name="enableStarvingDebuff" ValData={comment2.enableStarvingDebuff} Type="bol" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={4} Id3={0} /> <br></br>   
            foodBuffDurationFactor : {comment2.foodBuffDurationFactor} <EditSetting Name="foodBuffDurationFactor" ValData={comment2.foodBuffDurationFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={5} Id3={0} /> <br></br>
		        fromHungerToStarving : {comment2.fromHungerToStarving} <EditSetting Name="fromHungerToStarving" ValData={comment2.fromHungerToStarving} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={6} Id3={0} /> <br></br>
		        shroudTimeFactor : {comment2.shroudTimeFactor} <EditSetting Name="shroudTimeFactor" ValData={comment2.shroudTimeFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={7} Id3={0} /> <br></br> 
		        tombstoneMode : {comment2.tombstoneMode} <EditSetting Name="tombstoneMode" ValData={comment2.tombstoneMode} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={8} Id3={0} /> <br></br> 
		        miningDamageFactor : {comment2.miningDamageFactor} <EditSetting Name="miningDamageFactor" ValData={comment2.miningDamageFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={9} Id3={0} /> <br></br>
		        plantGrowthSpeedFactor : {comment2.plantGrowthSpeedFactor} <EditSetting Name="plantGrowthSpeedFactor" ValData={comment2.plantGrowthSpeedFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={10} Id3={0} /> <br></br> 
		        resourceDropStackAmountFactor : {comment2.resourceDropStackAmountFactor} <EditSetting Name="resourceDropStackAmountFactor" ValData={comment2.resourceDropStackAmountFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={11} Id3={0} /> <br></br>
		        factoryProductionSpeedFactor : {comment2.factoryProductionSpeedFactor} <EditSetting Name="factoryProductionSpeedFactor" ValData={comment2.factoryProductionSpeedFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={12} Id3={0} />  <br></br>
		        perkUpgradeRecyclingFactor : {comment2.perkUpgradeRecyclingFactor} <EditSetting Name="perkUpgradeRecyclingFactor" ValData={comment2.perkUpgradeRecyclingFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={13} Id3={0} />  <br></br>
		        perkCostFactor : {comment2.perkCostFactor}  <EditSetting Name="perkCostFactor" ValData={comment2.perkCostFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={14} Id3={0} /> <br></br>
		        experienceCombatFactor : {comment2.experienceCombatFactor} <EditSetting Name="experienceCombatFactor" ValData={comment2.experienceCombatFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={15} Id3={0} /> <br></br>
		        experienceMiningFactor : {comment2.experienceMiningFactor} <EditSetting Name="experienceMiningFactor" ValData={comment2.experienceMiningFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={16} Id3={0} />  <br></br>
		        experienceExplorationQuestsFactor : {comment2.experienceExplorationQuestsFactor}  <EditSetting Name="experienceExplorationQuestsFactor" ValData={comment2.experienceExplorationQuestsFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={17} Id3={0} />  <br></br>
		        randomSpawnerAmount : {comment2.randomSpawnerAmount} <EditSetting Name="randomSpawnerAmount" ValData={comment2.randomSpawnerAmount} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={18} Id3={0} />  <br></br>
		        aggroPoolAmount : {comment2.aggroPoolAmount} <EditSetting Name="aggroPoolAmount" ValData={comment2.aggroPoolAmount} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={19} Id3={0} />  <br></br>
		        enemyDamageFactor : {comment2.enemyDamageFactor} <EditSetting Name="enemyDamageFactor" ValData={comment2.enemyDamageFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={20} Id3={0} />  <br></br>
		        enemyHealthFactor : {comment2.enemyHealthFactor} <EditSetting Name="enemyHealthFactor" ValData={comment2.enemyHealthFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={21} Id3={0} />  <br></br>
		        enemyStaminaFactor : {comment2.enemyStaminaFactor} <EditSetting Name="enemyStaminaFactor" ValData={comment2.enemyStaminaFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={22} Id3={0} />  <br></br>
		        enemyPerceptionRangeFactor : {comment2.enemyPerceptionRangeFactor} <EditSetting Name="enemyPerceptionRangeFactor" ValData={comment2.enemyPerceptionRangeFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={23} Id3={0} />  <br></br>
		        bossDamageFactor : {comment2.bossDamageFactor} <EditSetting Name="bossDamageFactor" ValData={comment2.bossDamageFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={24} Id3={0} />  <br></br>
		        bossHealthFactor : {comment2.bossHealthFactor} <EditSetting Name="bossHealthFactor" ValData={comment2.bossHealthFactor} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={25} Id3={0} /> <br></br>
		        threatBonus : {comment2.threatBonus} <EditSetting Name="threatBonus" ValData={comment2.threatBonus} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={26} Id3={0} />  <br></br>
		        pacifyAllEnemies : {String(comment2.pacifyAllEnemies)} <EditSetting Name="pacifyAllEnemies" ValData={comment2.pacifyAllEnemies} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={27} Id3={0} /> <br></br>
		        dayTimeDuration : {comment2.dayTimeDuration} <EditSetting Name="dayTimeDuration" ValData={comment2.dayTimeDuration} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={28} Id3={0} />  <br></br> 
		        nightTimeDuration : {comment2.nightTimeDuration} <EditSetting Name="nightTimeDuration" ValData={comment2.nightTimeDuration} Type="num" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"gameSettings"} Id2={29} Id3={0} />  <br></br>
          
          </span>
          ))}


<span hidden = {spinHide} /> 

<br></br>


<span style={{fontSize:'1.5vw'}} >Group Settings</span>
<br></br>
{comment.userGroups.map( (comment3, index3) => (    
          <span key={index3} > 
            Name : {comment3.name}<br></br>
      

            Password : {comment3.password} <EditSetting Name={comment3.name +" password"} ValData={comment3.password} functEdit={handleEdit} functSetSpinHide={setSpinHide} Type="str" Id1={"userGroups"} Id2={index3} Id3={1} /> <br></br>
            KickBan : {String(comment3.canKickBan)} <EditSetting Name={comment3.name +" canKickBan"} ValData={comment3.canKickBan} functEdit={handleEdit} functSetSpinHide={setSpinHide} Type="bol" Id1={"userGroups"} Id2={index3} Id3={2} />  <br></br>
			      AccessInventories : {String(comment3.canAccessInventories)}  <EditSetting Name={comment3.name +" canAccessInventories"} ValData={comment3.canAccessInventories} functEdit={handleEdit} Type="bol" functSetSpinHide={setSpinHide} Id1={"userGroups"} Id2={index3} Id3={3} /> <br></br>
			      EditBase : {String(comment3.canEditBase)} <EditSetting Name={comment3.name +" canEditBase"} ValData={comment3.canEditBase} functEdit={handleEdit} functSetSpinHide={setSpinHide} Type="bol"  Id1={"userGroups"} Id2={index3} Id3={4} /> <br></br>
			      ExtendBase : {String(comment3.canExtendBase)} <EditSetting Name={comment3.name +" canExtendBase"} ValData={comment3.canExtendBase} functEdit={handleEdit} functSetSpinHide={setSpinHide} Type="bol" Id1={"userGroups"} Id2={index3} Id3={5} />  <br></br>
			      ReservedSlots : {String(comment3.reservedSlots)} <EditSetting Name={comment3.name +" reserved slots"} ValData={comment3.reservedSlots} functEdit={handleEdit} functSetSpinHide={setSpinHide} Type="num" Id1={"userGroups"} Id2={index3} Id3={6} /> <br></br>
		            <br></br>

          </span>
          ))}




<span hidden = {spinHide} /> 





      

    </span>
 ) 
  )}



</span>


<span hidden = {hidePAL}>

<span style={{fontSize:'1.5vw' }} >Server Settings</span>


    {palList.map( (comment, index) => (  
        <div key={index} > 
       AdminPassword : {comment.AdminPassword}  <EditSetting Name="Admin Password" ValName="AdminPassword" ValData={comment.AdminPassword} Type="str" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"main"} Id2={0} Id3={0} />  <br></br>
       ServerName : {comment.ServerName} <EditSetting Name="Server Name" ValName="ServerName" ValData={comment.ServerName} Type="str" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"main"} Id2={2} Id3={0} />  <br></br>
       ServerDescription : {comment.ServerDescription}  <EditSetting Name="Server Description" ValName="ServerDescription" ValData={comment.ServerDescription} Type="str" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"main"} Id2={3} Id3={0} /> <br></br>
       Difficulty : {comment.Difficulty}  <EditSetting Name="Difficulty" ValName="Difficulty" ValData={comment.Difficulty} Type="str" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"main"} Id2={4} Id3={0} /> <br></br>
       DayTimeSpeedRate : {comment.DayTimeSpeedRate} <EditSetting Name="Day Time Speed Rate" ValName="DayTimeSpeedRate" ValData={comment.DayTimeSpeedRate} Type="str" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"main"} Id2={5} Id3={0} />  <br></br>
       NightTimeSpeedRate : {comment.NightTimeSpeedRate}  <EditSetting Name="Night Time Speed Rate" ValName="NightTimeSpeedRate" ValData={comment.NightTimeSpeedRate} Type="str" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"main"} Id2={6} Id3={0} /> <br></br>
       ExpRate : {comment.ExpRate} <EditSetting Name="Exp Rate" ValName="ExpRate" ValData={comment.ExpRate} Type="str" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"main"} Id2={7} Id3={0} /> <br></br>
       DeathPenalty : {comment.DeathPenalty} <EditSetting Name="Death Penalty" ValName="DeathPenalty" ValData={comment.DeathPenalty} Type="str" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"main"} Id2={8} Id3={0} /> <br></br>
       CoopPlayerMaxNum : {comment.CoopPlayerMaxNum} <EditSetting Name="Coop Player Max Num" ValName="CoopPlayerMaxNum" ValData={comment.CoopPlayerMaxNum} Type="str" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"main"} Id2={9} Id3={0} /> <br></br>
       GuildPlayerMaxNum : {comment.GuildPlayerMaxNum} <EditSetting Name="Guild Player Max Num" ValName="GuildPlayerMaxNum" ValData={comment.GuildPlayerMaxNum} Type="str" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"main"} Id2={10} Id3={0} /> <br></br>
       ServerPassword : {comment.ServerPassword} <EditSetting Name="Server Password" ValName="ServerPassword" ValData={comment.ServerPassword} Type="str" functEdit={handleEdit} functSetSpinHide={setSpinHide} Id1={"main"} Id2={11} Id3={0} /> <br></br>
       
         </div>

    ) )}





</span>




            </Flex>









            

          </DialogContentText>
                  
        </DialogContent>



        { open5 === false ? (<>
        
        
          <DialogActions style={{ backgroundColor:tmpCol }}>
       
          <Button style={{ fontSize:'0.9vw'}} onClick={handleToCloseOptions}>
            EXIT
           </Button> 
        </DialogActions>

        
        </>) : (<>
          <DialogActions style={{ backgroundColor:tmpCol }}>

             

          <Button  style={{ fontSize:'0.9vw'}}  onClick={goUpdate} 
              color="primary" autoFocus
// variant="outlined"
                >


          SAVE
         </Button>


          <Button  style={{ fontSize:'0.9vw'}}  onClick={goDefault} 
              color="primary" autoFocus
// variant="outlined"
                >

          DEFAULT
         </Button>


<Button style={{ fontSize:'0.9vw'}} onClick={handleToCancelOptions}
//  variant="outlined"
>CANCEL
</Button> 



</DialogActions>
        

        </>) }


      </Dialog>











      <Flex 
position='fixed' 
bottom='2.5vh'
left='1vw'>
     <span hidden = {false} style={{ color: 'grey' , textAlign: "center", fontWeight: 'bold' , fontSize: '1.5vh' }} > 
STIME: tic({tic}-{counter}) skf({skipFact}/2) tos({tmputos})-2({tmputos2}) us({tmpustanding}) ureg({tmpureg}) det({tmpdet}) STATUS-stat({tmpstat}-2({tmpstat2})-state({tmpstate})-2({tmpstate2}) urdy({tmpuready}) Ldr({loader}) Ltc({loadTic})) OP5-FecthSkip({String(open5)})


 {
 //<button onClick={notify}>Notify</button>
}
</span>
<span hidden = {true} style={{ color: 'grey' , textAlign: "center", fontWeight: 'bold' , fontSize: '1.5vh' }} >  {fact2} </span>


</Flex>

<Flex 
direction='row'
justifyContent='flex-start'
//alignContent='center'
//alignItems='center'
gap="1px"
textAlign='left'
position='fixed' 
bottom='1vh'
right='1vw'
maxWidth='20vw'
wrap='wrap'
//backgroundColor='grey'
color='yellow'
opacity='.5'
fontSize='10px'
//borderStyle='solid'
borderWidth='1px'
padding='10px'
>
    
     {
     (fact2.split(',')).map( (fact, index) => (  <div key={index}>  {fact}  </div>   ) )
    }

</Flex>
    

    </div>

   



       
         </>
      );
    



    
}
    export default StatusTime;

