import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import appConfig from './AppConfig';
import { Flex } from '@aws-amplify/ui-react';
import { post } from 'aws-amplify/api';
//import {  toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
//import { API } from 'aws-amplify';
//import { generateUtilityClass } from '@mui/material';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();
//1.8a

function PaypalTime(props) { 

  
 
 
  
  const apiNamepaypal = 'gsapaypal'; // replace this with your api name.
  const path = '/items'; //replace this with the path you have configured on your API
  


  const [state, setState] = useState("--");
  const [state4, setState4] = useState("--");

  function stateChange(f) {
    setState(f);
    props.funct1b(f);
    //props.ustanding = f;
    };
    
    function stateChange4(f) {
      setState4(f);
      props.funct4(f);
      //uclass
      };
      
  


    function addTimeTestENSH8() {
      addENSHSubButton('TESTENSH000000','8','us-west-2')
    };
    
    
    function addTimeTestENSH8E1() {
      addENSHSubButton('TESTENSH000000','8','us-east-1')
    };
    

    function addTimeTestENSH16() {
      addENSHSubButton('TESTENSH000000','16','us-west-2')
    };
    
 



function addTimeTest16E1() {
  addSubButton('TEST000000','8','us-east-1')
};

function addTimeTest16() {
  addSubButton('TEST000000','16','us-west-2')
};

function addTimeTest32() {
  addSubButton('TEST000000','32','us-west-2')
};



async function addENSHSubButton(data, slots, reg) {

  var raw = JSON.stringify({"uname": props.uname,"act": "addTime_"+ appConfig.apiname,"dat": data, "slots": slots, "class": 'ENSHROUDED', "reg": reg});
  try {
    const restOperation = post({
      apiName: 'gscpaypal',
      path: '/items',
      options: {
        body: raw,
        headers: {'Content-Type': 'application/json'}, 
         redirect: 'follow', 
        
      }
    });
  
    console.log('PPAL POST call succeeded');

  } catch (e) {
    console.log('PPAL POST call failed: ', e);
  }
 stateChange("enabled") ;
 stateChange4("ENSHROUDED") 
  }




async function addSubButton(data, slots, reg) {

      var raw = JSON.stringify({"uname": props.uname,"act": "addTime_"+ appConfig.apiname,"dat": data, "slots": slots, "class": 'PALWORLD', "reg" : reg});
      try {
        const restOperation = post({
          apiName: 'gscpaypal',
          path: '/items',
          options: {
            body: raw,
            headers: {'Content-Type': 'application/json'}, 
             redirect: 'follow', 
            
          }
        });
      
        console.log('PPAL POST call succeeded');

      } catch (e) {
        console.log('PPAL POST call failed: ', e);
      }
     stateChange("enabled") ;
     stateChange4("PALWORLD") 
      }

    
      // production "client-id": "", 
      //sandbox "client-id": "Acjn36fbpAa_0ctQxBn1Ipgkqj-GpXNkMcD0TwGXdCCQCYIMzaTRIVXRtUlSX7DB5Kz9zlIoCDeuqBDy", 
    

      //P-1XC40242B15636748MS4356I


      const SubscriptionButtonWrapper = () => {
        var h = Math.round(window.innerWidth / 30);

          if (h > 50) {  h = 50}
          if (h < 25) {  h = 25}

        //var h = 50
        return (




          <Flex
          direction="column"
    gap="1vw"
    
    textAlign="center"
    alignItems="center"
    justifyContent= "center"
          marginTop='2vw'
          
          >

<div hidden>


8-Slots 30-Day Subscription <br></br>$19.99
       
<Flex
          direction="column"
          gap="0"
          
          textAlign="center"
          alignItems="center"
          justifyContent= "center"
                //marginTop='.1vw'
                
>

        <PayPalButtons
        createSubscription={(data, actions) => {
          return actions.subscription
            .create({
              plan_id: "P-85E79057CJ276903NMWNVFVQ",
            });
        }}
        style={{
       //   label: "subscribe",
          layout: "horizontal",
          tagline: false,
          height: h,
          
       //   color: "white"
        }}
        onApprove={(data, actions) => {
          return actions.subscription.capture(  addSubButton(data,'8')  );
         }}
         /> 







         </Flex>
        
         </div>

<div>


 <Flex 
direction="column"
//          gap="0"
          textAlign="center"
          alignItems="center"
          justifyContent= "center"                
>

         <button style={{ border: "none",color: "cyan", marginTop: "0px", marginBottom: "0px" , backgroundColor: "#4d4d4d",  display: "inline-block", fontSize: "12px", height: "21px", width: "170px" }} onClick={addTimeTest16}>TEST W-PAL-16</button>
         <button style={{ border: "none",color: "cyan", marginTop: "0px", marginBottom: "0px" , backgroundColor: "#4d4d4d",  display: "inline-block", fontSize: "12px", height: "21px", width: "170px" }} onClick={addTimeTest16E1}>TEST E-PAL-16</button>

<button style={{   border: "none",color: "cyan", marginTop: "0px", marginBottom: "0px" , backgroundColor: "#4d4d4d", display: "inline-block", fontSize: "12px", height: "21px", width: "170px" }} onClick={addTimeTest32}>TEST W-PAL-32</button>


<button style={{ border: "none",color: "yellow", marginTop: "0px", marginBottom: "0px" , backgroundColor: "#4d4d4d" , display: "inline-block", fontSize: "12px", height: "21px", width: "170px" }} onClick={addTimeTestENSH8}>TEST W-ENSH-8</button>
<button style={{ border: "none",color: "yellow", marginTop: "0px", marginBottom: "0px" , backgroundColor: "#4d4d4d" , display: "inline-block", fontSize: "12px", height: "21px", width: "170px" }} onClick={addTimeTestENSH8E1}>TEST E-ENSH-8</button>

<button style={{  border: "none",color: "yellow", marginTop: "0px", marginBottom: "0px" , backgroundColor: "#4d4d4d",  display: "inline-block", fontSize: "12px", height: "21px", width: "170px" }} onClick={addTimeTestENSH16}>TEST W-ENSH-16</button>

</Flex>

</div>

        </Flex>
        

        
        );
      };


   



       return (<>
<Flex 
 direction="column"
 textAlign="center"
 alignItems="center"
//maxWidth = "150px"
//minHeight = "96px"
//paddingTop="1vw"
gap = "0"
fontSize="2vw"
>




<PayPalScriptProvider
			options={{
				"client-id": "ARTGesgzUqj887zz644Has2xSMpnG4m5rAQTZ9Yo07PNKuyr-5_LEI8wnJtMkKLns1cc8s_XKc_v3AEM",  
				components: "buttons",
				intent: "subscription",
				vault: true,
			}}
		>
			<SubscriptionButtonWrapper /> 
		</PayPalScriptProvider>





  
</Flex>    

</>
);

}

export default PaypalTime;









