import React, { useState} from 'react';
//import PaypalSubs from "./PaypalSubs";
import PaypalTime from "./PaypalTime";
import Regions from "./Regions";
import appConfig from './AppConfig';
import {
  Flex,
//  Heading,
//  Image,
//  View,
} from "@aws-amplify/ui-react";
//import { API } from 'aws-amplify'
import { generateClient } from 'aws-amplify/api';
import { post } from 'aws-amplify/api';
//import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
//import TextField from '@mui/material/TextField';

const client = generateClient();


//v1.8b

function Body(props) { 

  const apiName = 'gscapi'; // replace this with your api name.
  const path = '/items'; //replace this with the path you have configured on your API



  const [state, setState] = useState("--");
  const [state2, setState2] = useState("--");

  const [state3, setState3] = useState("--");
  const [state4, setState4] = useState("--");

  const [uopt, setUopt] = useState("0");
  const [uopt2, setUopt2] = useState("0");

  const [ureg, setUreg] = useState("--");
  const [uclass, setUclass] = useState("--");

  const [open4, setOpen4] = React.useState(false);




  const handleToClose4 = () => {
    setOpen4(false);
  };
  








 function stateChange(f) {
        setState(f);
        props.funct(f);
    //   to set ustanding
  };

  function stateChange2(f) {  
    setState2(f);
    props.funct2(f);
    //to set utos
};


function stateChange3(f) {
  //alert('SC');
 
  setState3(f);
  props.funct3(f);
  //to set ureg
};

function stateChange4(f) {
  //alert('SC');
 
  setState4(f);
  props.funct4(f);
  //to set uclass
};


  function goTOS() {
    //Open help/support page in new window
     window.open("https://" + appConfig.websitename + "/tos");
  }
  
  
  function goPrivPol() {
    //Open help/support page in new window
     window.open("https://" + appConfig.websitename + "/privpol");
  }
  
  
  async function goAcceptTOS() {
    
    
//    var myHeaders = new Headers();
//    myHeaders.append("Content-Type", "application/json");
//    var raw = JSON.stringify({"uname": props.value,"act": "tos_"+ appConfig.apiname});
//    var myInit = {
//   body: raw,
//   headers: {
//     'Content-Type': 'application/json'}, 
//     redirect: 'follow',
// };
// client.post(apiName, path, myInit)
// .catch((error) => {
//  alert(appConfig.appname + " TOS API ERROR: " + error.message + ' ' + error.response) 
//});
  
var raw = JSON.stringify({"uname": props.value,"act": "tos_"+ appConfig.apiname});
try {
  const restOperation = post({
    apiName: 'gscapi',
    path: '/items',
    options: {
      body: raw,
      headers: {'Content-Type': 'application/json'}, 
       redirect: 'follow', 
      
    }
  });

  const { body } = await restOperation.response;
 const response = await body.text();

  console.log('BDY-TOS POST call succeeded');
  //console.log(response);
  //alert('Restart Command Sent')
} catch (e) {
  console.log('BDY-TOS POST call failed: ', e);
}

   stateChange2("1")
  };




  var tmpustanding = props.ustanding;

  if (state === "--") {
    var tmpustanding = props.ustanding
  } else if (state === "enabled") {
    tmpustanding = "enabled"
  };
  

if (state2 === "--") {
  var tmputos = props.utos
} else if (state2 === "1") {
  tmputos = "1"
};

if (state3 === "--") {
  var tmpureg = props.ureg
} else  {
  tmpureg = state3
};




if (ureg === "--") {
  tmpureg = props.ureg
} else if (ureg !== "--") {
  tmpureg = ureg;
};









//var tmpATClass = 'AT-button';


if (tmpustanding === 'disabled') {

  if (tmputos === '1') {
    var tmphPP = false
  } else {
    tmphPP = true
  }

} else {


  

  tmphPP = true



}




return (

    




<div style={{ textAlign: "center" }}>
  


<Flex
  direction="column"
 textAlign="center"
 alignItems="center"
//fontSize="5px"
// /minHeight="18vw"
gap='1vw'
>






		{tmputos === "1" ?     (<>


      

        </>) : (
        
          tmputos === "0" ?     (<>
        
      
        <button  className = 'Misc-button' style={{ border: "none", borderRadius: '30px' ,  display: "inline-block", marginTop: "3vw", marginBottom: "0vw", fontWeight:'bold', fontSize: '1.3vw',  height: "auto", width: "20vw"  }} onClick={goTOS}>TERMS OF SERVICE</button>
        <button  className = 'Misc-button' style={{ border: "none", borderRadius: '30px' ,  display: "inline-block", marginTop: "0vw", marginBottom: "0vw", fontWeight:'bold', fontSize: '1.3vw',  height: "auto", width: "20vw"  }}  onClick={goPrivPol}>PRIVACY POLICY</button>
        <button className = 'SP-button' style={{ border: "none", borderRadius: '30px' ,  display: "inline-block", marginTop: "2.5vw", marginBottom: "4.9vw", fontWeight:'bold', fontSize: '1.3vw',  height: "auto", width: "20vw"  }}  onClick={goAcceptTOS}>ACCEPT</button>


         </>) : (<></>)

        )    
        }




      <span hidden = {tmphPP}  >

        

<Flex
  direction="column"
 textAlign="center"
 alignItems="center"
fontSize="5px"
minHeight="17.7vw"
>



{
     
  


      tmpureg ==="not set" ? ( <>
      
      <Regions uname={props.value} ureg={props.ureg} funct3b={stateChange3} />
      
      </> ) : (   <>
      
      
        <PaypalTime uname={props.value} funct1b={stateChange} funct4={stateChange4}/>

      </>     )       }











</Flex>

      </span>


      </Flex>




     <div stlye={{}}>




</div>



<Flex 
position='fixed' 
bottom='5vh'
left='1vw'>
      <span hidden = {appConfig.debugHide}  style={{ color: 'grey' , textAlign: "center", fontWeight: 'bold' , fontSize: '0.8vw' }} >BODY: us({tmpustanding}) ureg({tmpureg}) utos({tmputos}) </span> 
</Flex>



</div>








)

}

export default Body;