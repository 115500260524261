import React from "react";




function EditSetting( props)  {


 //   const [state, setState] = useState("--");
 const [state, setState] = React.useState("");
//    const [state2, setState2] = useState("--");
 

    function stateChange(f) {
        setState(f);
        props.functEdit(f,props.Id1,props.Id2,props.Id3, props.Type);
        props.functSetSpinHide(false);
      //  alert(props.Id1);
      //  alert(props.Id2);
      //  alert(props.Id3);
    //   to set value change
  };




function blank(){};


function click(){
//    alert(props.Name + " " + props.Val)    

    const input = prompt(props.Name, props.ValData);
    if (input === null) { 
     // alert("Reset Cancelled")
    } else {
    //    alert(input);
      stateChange(input)
      
    }
  

};







    return (<>

        <button className = "editButton"   onClick={click} ></button>
        </>);
};

export default EditSetting;