import { Heading, useTheme, Flex } from "@aws-amplify/ui-react";


export function SignInHeader() {
  const { tokens } = useTheme();

  return (


    <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`} >

<Flex
  direction="row"
  justifyContent="center"
  alignItems = "center"
  textAlign="center"
  >
      <span style={{fontSize:'calc(1vw + 12px)' , color:'#FB8B24'}}>Sign in to your Account</span>

      </Flex>
    </Heading>


  );
}
