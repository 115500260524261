import {  useTheme, Flex } from "@aws-amplify/ui-react";


export function Header() {
  const { tokens } = useTheme();

  return (
    



<Flex
  direction="row"
  justifyContent="center"
  alignItems = "center"
  textAlign="center"
  backgroundColor="#e4e1e1"

>
  <span className = "navBarTitleAuth" style={{ textDecoration: 'none' }} >G<span  style={{ color: '#2568FB' }}  >SERVER</span>.CLOUD</span>
</Flex>
  


  );
}
