//v1.8a
const appConfig = {
    "ver": " ",
    "appname": "GServer Cloud",  
    "webtitle": "GSC Portal", 
    "apiname": "gsc", 
    "websitename": "gserver.cloud", 

    "debugHide":true,


};

export default appConfig;
