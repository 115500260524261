//import { Amplify } from "aws-amplify";
//import amplifyconfig from './amplifyconfiguration.json';
import { Amplify } from 'aws-amplify';
import { getCurrentUser } from 'aws-amplify/auth';

import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.




import { post } from 'aws-amplify/api';
import React, { useEffect, useMemo, useState } from "react";
import './App.css';

import { withAuthenticator } from "@aws-amplify/ui-react";
import { fetchAuthSession } from 'aws-amplify/auth';
import { Header } from "./Header";
import { Footer } from "./Footer";
import { SignInHeader } from "./SignInHeader";
import { SignUpHeader } from "./SignUpHeader";
import { SignInFooter } from "./SignInFooter";
import { SignUpFooter } from "./SignUpFooter";
import "./styles.css";


import Dialog from "@mui/material/Dialog";
import Navbar from "./navbar"
import PaypalTime from "./PaypalTime";

import logo from './785.svg';
import StatusTime from "./StatusTime";
import Body from "./Body";
import appConfig from './AppConfig';

//import background from "./ncs1f2.jpg";
import {
  Flex,
} from "@aws-amplify/ui-react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
//import { Authenticator } from '@aws-amplify/ui-react';
import login from './login-logo1.png';
import menu from './menu-logo1.png';

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

//const client = post();


//const path = window.location.path;
//const authState = 'signUp';

if (window.location.pathname === '/signup/' )  {
   var tmpAuthState = "signUp";
   var tmpHideSignUp = false
} else if (window.location.pathname === '/signup' )  {
   var tmpAuthState = "signUp";
   var tmpHideSignUp = false
} else {
   tmpAuthState = "signIn";
   tmpHideSignUp = true
};





//async function currentAuthenticatedUser() {
  try {
    const { username, userId, signInDetails } = await getCurrentUser();
    //console.log(`The username: ${username}`);
    //console.log(`The userId: ${userId}`);
   // console.log(`The signInDetails: ${signInDetails}`);
  //const { username } = await getCurrentUser();
  //  console.log(await getCurrentUser());
  

  } catch (err) {
    console.log(err);
  }

 //async function currentSession() {
 //   try {
 //     const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
 //     console.log(idToken)
 //   } catch (err) {
 //     console.log(err);
 //   }
  //}




//}



export function App({ signOut, user }) {




 
 // const apiName = 'gscapi'; // replace this with your api name.
 // const path = '/items'; //replace this with the path you have configured on your API
  
  
   // const [uname, setUname] = useState(user.attributes.email);
    const [ustat, setUstat] = useState("--");
    const [ustate, setUstate] = useState("--");
    
    const [udet, setUdet] = useState("--");
    const [email, setEmail] = useState("");

    const [fact2, setFact2] = useState("");

    
    //const [upass, setUpass] = useState("--");
  
   const [ulastbackup, setUlastbackup] = useState("--");
  
    const [utos, setUtos] = useState("--");

    const [ureg, setUreg] = useState("--");
    const [uready, setUready] = useState("--");

    const [uclass, setUclass] = useState("--");
    const [uslots, setUslots] = useState("--");
    const [uip, setUip] = useState("--");
    const [utype, setUtype] = useState("--");
    const [uconfig, setUconfig] = useState("--");

    const [ustanding, setUstanding] = useState("--");
    const [uGS, setUGS] = useState("--");
    const [ucurGS, setUcurGS] = useState("--");

    const [GS1, setGS1] = useState("--");
    const [GS2, setGS2] = useState("--");
    const [GS3, setGS3] = useState("--");
    const [GS4, setGS4] = useState("--");
    const [GS5, setGS5] = useState("--");
    const [GS6, setGS6] = useState("--");
    const [GS7, setGS7] = useState("--");
    const [GS8, setGS8] = useState("--");
    const [GS9, setGS9] = useState("--");
    const [GS10, setGS10] = useState("--");


    const [uerr, setUerr] = useState("Connecting to Service...");
  
    const [loader, setLoader] = React.useState(0);
  
    const [reload, setReload] = React.useState(0);

    const [open, setOpen] = React.useState(true);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(true);
    const [openGS, setOpenGS] = React.useState(1);
  
    const [openS1, setOpenS1] = React.useState(false);
    const [openS2, setOpenS2] = React.useState(false);
    const [openS3, setOpenS3] = React.useState(false);
    const [openS4, setOpenS4] = React.useState(false);
    const [openS5, setOpenS5] = React.useState(false);
    const [openS6, setOpenS6] = React.useState(false);
    const [openS7, setOpenS7] = React.useState(false);
    const [openS8, setOpenS8] = React.useState(false);
    const [openS9, setOpenS9] = React.useState(false);
    const [openS10, setOpenS10] = React.useState(false);

    const [configList, setConfigList] = React.useState([{}])
    //const [GSList, setGSList] = useState([]);



    
    const handleOpen = (props) => {
     // setLoader(1);
      setOpen(props);
   //  statusButton1("0");
   setOpenGS(0);
    };
    
    //const handleToClose = () => {
    //  setOpen(false);
    //  setOpen2(true);
    //};
    




    function stateChange(f) {
 
};

function stateChange4(f) {
  setOpen(false);
    statusButton1("0");
  setOpenGS(0);
};

    
    function getDat(res) {
  
      var tmpstr = res.substring( res.indexOf("|") + 1,res.lastIndexOf("~") );
      setUerr('0');
  //alert(res);
      var tmpstat = tmpstr.split("!")[0];
      var tmpuready = tmpstr.split("!")[1];
      var tmpustanding = tmpstr.split("!")[5];
      var tmpulastbackup = tmpstr.split("!")[6];
      var tmputos = tmpstr.split("!")[7];
      
      var tmpureg = tmpstr.split("!")[10];
      var tmpuclass = tmpstr.split("!")[12];
      var tmpuconfig = tmpstr.split("!")[11];
      var tmpuip = tmpstr.split("!")[17];
      var tmpuGS = tmpstr.split("!")[18];
      var tmpucurGS = tmpstr.split("!")[19];
    //  var tmpuGSS = tmpstr.split("!")[19];

      var tmpdet = tmpstr.split("!")[3];
      var tmpstate = tmpstr.split("!")[4];
      var tmpuslots = tmpstr.split("!")[13];
      var tmputype = tmpuclass.split("-")[0];
      //var tmp = tmpstr.split("!<<<<")[1];
      //var tmpupass = tmp.substring( tmp.indexOf("<<<<") + 1,tmp.lastIndexOf(">>>>") );
      setUtype(tmputype)
      setUstat(tmpstat);
      setUstate(tmpstate);

      setUdet(tmpdet);
      setUstanding(tmpustanding);
      setUtos(tmputos);

      setUreg(tmpureg);
      setUready(tmpuready);
      setUclass(tmpuclass);
      setUslots(tmpuslots);

      setUip(tmpuip);
      
      setUconfig(tmpuconfig);
      setConfigList(tmpuconfig);
     // console.log("configList:" + configList)

     //setUpass(tmpupass);
     setUlastbackup(tmpulastbackup);


      setUGS(tmpuGS);
      setUcurGS(tmpucurGS);
      var tmpGSCount = tmpuGS.split(";")[0];
      var tmpGS1 = tmpuGS.split(";")[1];
      var tmpGS2 = tmpuGS.split(";")[2];
      var tmpGS3 = tmpuGS.split(";")[3];
      var tmpGS4 = tmpuGS.split(";")[4];
      var tmpGS5 = tmpuGS.split(";")[5];
      var tmpGS6 = tmpuGS.split(";")[6];
      var tmpGS7 = tmpuGS.split(";")[7];
      var tmpGS8 = tmpuGS.split(";")[8];
      var tmpGS9 = tmpuGS.split(";")[9];
      var tmpGS10 = tmpuGS.split(";")[10];
      setGS1(tmpGS1);
      setGS2(tmpGS2);
      setGS3(tmpGS3);
      setGS4(tmpGS4);
      setGS5(tmpGS5);
      setGS6(tmpGS6);
      setGS7(tmpGS7);
      setGS8(tmpGS8);
      setGS9(tmpGS9);
      setGS10(tmpGS10);


     // setUGSS(tmpuGSS);
     //setLoader(0)

      getDatEnd()

    };
  
  
 function getDatEnd() {
  setLoader(0)
 }


 function goBlank() {
  
 }


    function getDat2(res) {
  
      var tmpstr = res.substring( res.indexOf("|") + 1,res.lastIndexOf("~") );
      setUerr('0');
  //alert(res);
      var tmpstat = tmpstr.split("!")[0];
      var tmpuready = tmpstr.split("!")[1];
      var tmpustanding = tmpstr.split("!")[5];
      var tmpulastbackup = tmpstr.split("!")[6];
      var tmputos = tmpstr.split("!")[7];
      
      var tmpureg = tmpstr.split("!")[10];
      var tmpuclass = tmpstr.split("!")[12];
      var tmpuconfig = tmpstr.split("!")[11];
      var tmpuip = tmpstr.split("!")[17];
      var tmpuGS = tmpstr.split("!")[18];
      var tmpucurGS = tmpstr.split("!")[19];
    //  var tmpuGSS = tmpstr.split("!")[19];

      var tmpdet = tmpstr.split("!")[3];
      var tmpstate = tmpstr.split("!")[4];
      
      //var tmp = tmpstr.split("!<<<<")[1];
      //var tmpupass = tmp.substring( tmp.indexOf("<<<<") + 1,tmp.lastIndexOf(">>>>") );
      var tmputype = tmpuclass.split("-")[0];
      var tmpuslots = tmpstr.split("!")[13];
      //var tmp = tmpstr.split("!<<<<")[1];
      //var tmpupass = tmp.substring( tmp.indexOf("<<<<") + 1,tmp.lastIndexOf(">>>>") );
      setUtype(tmputype)
      setUstat(tmpstat);
      setUstate(tmpstate);

      setUdet(tmpdet);
      setUstanding(tmpustanding);
      setUtos(tmputos);

      setUreg(tmpureg);
      setUready(tmpuready);
      setUclass(tmpuclass);
      setUslots(tmpuslots);
      setUip(tmpuip);
      
      setUconfig(tmpuconfig);

     //setUpass(tmpupass);
     setUlastbackup(tmpulastbackup);

     setConfigList(tmpuconfig);
    // console.log("configList(2):" + configList)



      setUGS(tmpuGS);
      setUcurGS(tmpucurGS);
      var tmpGSCount = tmpuGS.split(";")[0];
      var tmpGS1 = tmpuGS.split(";")[1];
      var tmpGS2 = tmpuGS.split(";")[2];
      var tmpGS3 = tmpuGS.split(";")[3];
      var tmpGS4 = tmpuGS.split(";")[4];
      var tmpGS5 = tmpuGS.split(";")[5];
      var tmpGS6 = tmpuGS.split(";")[6];
      var tmpGS7 = tmpuGS.split(";")[7];
      var tmpGS8 = tmpuGS.split(";")[8];
      var tmpGS9 = tmpuGS.split(";")[9];
      var tmpGS10 = tmpuGS.split(";")[10];
      setGS1(tmpGS1);
      setGS2(tmpGS2);
      setGS3(tmpGS3);
      setGS4(tmpGS4);
      setGS5(tmpGS5);
      setGS6(tmpGS6);
      setGS7(tmpGS7);
      setGS8(tmpGS8);
      setGS9(tmpGS9);
      setGS10(tmpGS10);


     // setUGSS(tmpuGSS);

 

    };
  
  
    
    function getEmail(res) {
      setEmail(res)
    };

    function goGS1(res) {
      setOpen(true);
      setOpen2(false);
      setOpenGS(1);
    setLoader(1);
    setReload(1);
    statusButton1("1");
    };

    function goGS2(res) {
      
      setOpen(true);
      setOpen2(false);
      setOpenGS(2);
      setLoader(1);
      setReload(1);
      statusButton1("2");
    };


    function goGS3(res) {
      
      setOpen(true);
      setOpen2(false);
      setOpenGS(3);
      setLoader(1);
      setReload(1);
      statusButton1("3");
    };


    function goGS4(res) {
      
      setOpen(true);
      setOpen2(false);
      setOpenGS(4);
      setLoader(1);
      setReload(1);
      statusButton1("4");
    };


    function goGS5(res) {
      
      setOpen(true);
      setOpen2(false);
      setOpenGS(5);
      setLoader(1);
      setReload(1);
      statusButton1("5");
    };


    function goGS6(res) {
      
      setOpen(true);
      setOpen2(false);
      setOpenGS(6);
      setLoader(1);
      setReload(1);
      statusButton1("6");
    };


    function goGS7(res) {
      
      setOpen(true);
      setOpen2(false);
      setOpenGS(7);
      setLoader(1);
      setReload(1);
      statusButton1("7");
    };


    function goGS8(res) {
      
      setOpen(true);
      setOpen2(false);
      setOpenGS(8);
      setLoader(1);
      setReload(1);
      statusButton1("8");
    };


    function goGS9(res) {
      
      setOpen(true);
      setOpen2(false);
      setOpenGS(9);
      setLoader(1);
      setReload(1);
      statusButton1("9");
    };


    function goGS10(res) {
      
      setOpen(true);
      setOpen2(false);
      setOpenGS(10);
      setLoader(1);
      setReload(1);
      statusButton1("10");
    };

    function goGSnew(res) {
      
      setOpen(true);
      setOpen2(true);
      setOpen3(false)
  //    setOpenGS(10);
  //    setLoader(1);
  //    setReload(1);
  //    statusButton1("10");
    };

    function goGScancel(res) {
      
      setOpen(true);
      setOpen2(false);
      setOpen3(true)
  //    setOpenGS(10);
  //    setLoader(1);
  //    setReload(1);
  //    statusButton1("10");
    };



    const [init, setInit] = useState(false);
		const particlesLoaded = (container) => {
			//console.log(container);
		  };
		
	


		  const options = useMemo(
			() => ({
			  background: {
				color: {
				  value: "#000000",
				},
			  },
			  fpsLimit: 120,
			  interactivity: {
				events: {
				  onClick: {
					enable: false,
					mode: "push",
				  },
				  onHover: {
					enable: false,
					mode: "repulse",
				  },
				},
				modes: {
				  push: {
					enable: false,
					quantity: 4,
				  },
				  repulse: {
					enable: false,
					distance: 200,
					duration: 0.4,
				  },
				},
			  },
			  particles: {
				color: {
				  value: '#FB8B24',
				},
				links: {
				  color: "#ffffff",
				  distance: 150,
				  enable: false,
				  opacity: 0.7,
				  width: 1,
				},
				move: {
				  direction: "none",
				  enable: true,
				  outModes: {
					default: "out",
				  },
				  random: true,
				  speed: 3,
				  straight: false,
				},
				number: {
				  density: {
					enable: true,
				  },
				  value: 120,
				},
				opacity: {
				  value: 0.08,
				},
				shape: {
				  type: "square",
				},
				size: {
				  value: { min: 10, max: 150 },
				},
				
			  },
			  detectRetina: true,
			}),
			[],
		  );




      async function statusButton1(parms) {
     //   alert(parms)
        var raw = JSON.stringify({"uname": user.username,"act": "status_"+ appConfig.apiname, "dat": parms });
    try {
      const restOperation = post({
        apiName: 'gscapi',
        path: '/items',
        options: {
          body: raw,
          headers: {'Content-Type': 'application/json'}, 
           redirect: 'follow', 
        }
      });
      const { body } = await restOperation.response;
     const response = await body.text();  
      console.log('AP1 POST call succeeded');
      //console.log(response);
      getDat(response) ;    
    } catch (e) {
      console.log('AP1 POST call failed: ', e);
    }
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      //console.log(idToken.payload.email)
      getEmail(idToken.payload.email);
    } catch (err) {
      console.log(err);
    }}










    useEffect(() => {
  
      async function statusButton2() {


        if (openGS !== 0) {
        
        // var raw = JSON.stringify({"uname": user.attributes.email,"act": "status_"+ appConfig.apiname});
        var raw = JSON.stringify({"uname": user.username,"act": "status_"+ appConfig.apiname, "dat": openGS });
  


  
    try {
      const restOperation = post({
        apiName: 'gscapi',
        path: '/items',
        options: {
          body: raw,
          headers: {'Content-Type': 'application/json'}, 
           redirect: 'follow', 
          
        }
      });
  
      const { body } = await restOperation.response;
     const response = await body.text();
  
      console.log('AP2 POST call succeeded');
      //console.log(response);
      getDat2(response) ;
      
    } catch (e) {
      console.log('AP2 POST call failed: ', e);
    }
  

    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      //console.log(idToken.payload.email)
      getEmail(idToken.payload.email);
    } catch (err) {
      console.log(err);
    }
  


//console.log(user)

  }

       };
  
  
  
  
      //checkUser();
      statusButton2();
      
      window.history.pushState({}, null, "/");

    }, []);
  
  
  
   
  
    useEffect(() => {
      document.title = 'GServer.Cloud';


      initParticlesEngine(async (engine) => {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadAll(engine);
        //await loadFull(engine);
        await loadSlim(engine);
        //await loadBasic(engine);
        }).then(() => {
        setInit(true);
        });
  


      }, []);
  
  
  
    
      function goHelp() {
        //Open help/support page in new window
         window.open("https://" + appConfig.websitename + "/help");
      }


  
    function goError(err) {
  
      setUerr(err);
     //alert(appConfig.appname + "ERROR!! (214) " + err);
  
  
    };
  
  //var fact2 = "";
  



     
if (uerr === '0') {
   var hideErr = true;
   var hideBody = false;
   var hideSpin = true;

  } else {
    hideErr = false;
    hideBody = true;
    
    if (uerr === 'Connecting to Service...') {
      hideSpin = false;
      var eclass = "App-stopped";
      var dispuerr = "";
  

    } else {
      hideSpin = true
      eclass = "App-processing";
      dispuerr = uerr;
    }
 

  };
  
  
  
  if (loader === 1) {
    hideSpin = false;
    hideBody = true;
    var hideErr = false;
    var eclass = "App-stopped";
    var dispuerr = "";  
   } 

   var hideNew = false ;
   if (GS1 === '*') { var hide1 = true  } else { hide1 = false};
   if (GS2 === '*') { var hide2 = true  } else { hide2 = false};
   if (GS3 === '*') { var hide3 = true  } else { hide3 = false};
   if (GS4 === '*') { var hide4 = true  } else { hide4 = false};
   if (GS5 === '*') { var hide5 = true  } else { hide5 = false};
   if (GS6 === '*') { var hide6 = true  } else { hide6 = false};
   if (GS7 === '*') { var hide7 = true  } else { hide7 = false};
   if (GS8 === '*') { var hide8 = true  } else { hide8 = false};
   if (GS9 === '*') { var hide9 = true  } else { hide9 = false};
   if (GS10 === "*") { var hide10 = true } else { hide10 = false; hideNew = true};
   



  return (
    

    <main>


<Particles id="tsparticles" particlesLoaded={particlesLoaded} options={options} />


     
	<div style={{  zIndex:'1', color: 'white' , opacity:'1',  minWidth: "100vw", minHeight: "100vh",  height:'auto'   }}>

<Navbar signOut={signOut} funct={handleOpen} funct2={setOpen2} ></Navbar>


  
  
  







 <Flex
 direction="column"
 textAlign="center"
 alignItems="center"
 gap="15px"
 zIndex='1'
 //maxHeight="10vh"
 //backgroundColor=''
 >
      
      <div className = "App" style={{ zIndex:'1',  fontSize: "calc(5px + 2vw)", display: "inline",  fontWeight: "normal",color: "white",  border: "4px" , borderStyle: "none" , borderColor: "" ,  textAlign: "center" , minHeight: '70vh' }} >
      
      
<span hidden={hideErr} className = {eclass}>
<Flex  
direction="column"
textAlign="center"
alignItems="center"
gap='0px'
> 



<span style={{fontSize: 'calc(5px + 2.4vw)'}}>
{dispuerr}
</span>
<span hidden={hideSpin} >
<Flex 
      
      direction="column"
  paddingTop="20vw"
  //paddingBottom="9px"
     textAlign="center"
     alignItems="center"
     justifyContent= "center"
     gap = '0px'
     //minHeight={appConfig.minHeightD }
     >     
      
      <img src={logo} className="App-logo" alt="logo" />
      
      </Flex>
      </span>

</Flex>
</span>


<span hidden={hideBody} >
      <Flex
         direction="column"
         textAlign="center"
         alignItems="center"
        gap='0'
        minHeight={appConfig.minHeightB}
         
        >

  
     <Flex
        direction="column"
        textAlign="center"
        alignItems="center"
        borderRadius='5vw'
     boxShadow= "inset 0 0 0 .5vh white"
    minWidth="55vw"
    maxWidth="55vw"
  //minHeight="55vh"
  //maxHeight="55vh"
     marginTop='3vw'
     //paddingTop='2vw'
     //paddingBottom='4.5vw'
     paddingLeft='10vw'
     paddingRight='10vw'
     gap='0px'
     backgroundColor='blacK'
     zIndex='1'
          >




<div hidden = {open2} stlye={{}}> 

{
          <StatusTime value={user.username} openGS={openGS} configList={configList} utype={utype} ulastbackup={ulastbackup} uconfig={uconfig} utos={utos} ustat={ustat} ustate={ustate} udet={udet} ustanding={ustanding} ureg={ureg} uready={uready} uclass={uclass} uip={uip} uslots={uslots} fact={fact2} reload={reload} funct={setUstanding} functReload={setReload} />
}
     

            
             <Body value={user.username} utos={utos} ustanding={ustanding} funct={setUstanding} funct2={setUtos} funct3={setUreg} funct4={setUclass}  ustatus={ustat} ureg={ureg}  />
   

</div>





<div hidden = {open} stlye={{ display: 'flex' , position:'absolute' , top:'0' ,   fontSize:'10px' , backgroundColor: 'green' ,  zIndex:'1', color: 'white' , opacity:'1',  minWidth: "100vw", minHeight: "100vh",  height:'auto'  }}> 

<Flex 
      
      direction="column"
      paddingTop="25px"
      
      minHeight="200px"
      textAlign="center"
      alignItems="center"
      justifyContent= "center"
      //backgroundColor='blue'
      fontWeight='bold'
      >     
        

         <span style={{color:'#FB8B24'}}> MY SERVERS</span>




         <Flex 
      
      direction="row"
      //paddingTop="1vw"
      
      
      textAlign="center"
      alignItems="center"
      justifyContent= "center"
      fontSize='12px'
      //backgroundColor='yellow'
      > 


<Flex 
      
      direction="column"
      //paddingTop="1vw"
      
      
      textAlign="center"
      alignItems="center"
      justifyContent= "center"
      fontSize='16px'
      //backgroundColor='red'
      wrap='wrap'
      maxHeight = '450px'
      paddingBottom='25px'
      >     
          



         <span hidden = {hide1} ><button className = "serverLink3" onClick={goGS1}>{GS1}</button></span> 
         <span hidden = {hide2} >    <button className = "serverLink3" onClick={goGS2}>{GS2}</button> </span> 
         <span hidden = {hide3} > <button className = "serverLink3"  onClick={goGS3}>{GS3}</button> </span> 
         <span hidden = {hide4} >    <button className = "serverLink3"  onClick={goGS4}>{GS4}</button> </span> 
         <span hidden = {hide5} >   <button className = "serverLink3" onClick={goGS5}>{GS5}</button> </span> 
         <span hidden = {hide6} >   <button className = "serverLink3" onClick={goGS6}>{GS6}</button> </span> 
         <span hidden = {hide7} >   <button className = "serverLink3" onClick={goGS7}>{GS7}</button> </span> 
         <span hidden = {hide8} >   <button className = "serverLink3"  onClick={goGS8}>{GS8}</button> </span> 
         <span hidden = {hide9} >   <button className = "serverLink3"  onClick={goGS9}>{GS9}</button> </span> 
         <span hidden = {hide10} >    <button className = "serverLink3"  onClick={goGS10}>{GS10}</button> </span> 
         <span hidden = {true} >    <button className = "serverLink3blank" onClick={goBlank}></button> </span> 
         <span hidden = {hideNew} >    <button className = "serverLink3blue" onClick={goGSnew}>+ ADD SERVER</button> </span> 



      </Flex>




      

</Flex>



</Flex>

</div>

















<div hidden = {open3} stlye={{ display: 'flex' , position:'absolute' , top:'0' ,   fontSize:'10px' , backgroundColor: 'green' ,  zIndex:'1', color: 'white' , opacity:'1',  minWidth: "100vw", minHeight: "100vh",  height:'auto'  }}> 

<Flex 
      
      direction="column"
     // paddingTop="25px"
      
      minHeight="400px"
      textAlign="center"
      alignItems="center"
      justifyContent= "center"
      //backgroundColor='blue'
      //fontWeight='bold'
      >     
        

         <span style={{color:'#FB8B24', fontWeight:'bold'}}> ADD NEW SERVER</span>




         <PaypalTime uname={user.username} funct1b={stateChange} funct4={stateChange4}/>


         <span hidden = {hideNew} >    <button className = "serverLink3" onClick={goGScancel}>CANCEL</button> </span> 



</Flex>

</div>




















      </Flex>


      </Flex>
      </span>     

 

    
           

      

      </div>
      

    

        </Flex>


    

      <ToastContainer
      //position="top-right"
      //progressClassName="toastProgress"
      //bodyClassName="toastBody"
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      //theme="dark"
      //theme="light"
      theme="colored"
      icon={true}
      >
      </ToastContainer>
  


    
    



    </div>














    <Flex 
position='fixed' 
bottom='0vh'
left='1vw'
maxWidth='30vw'
>
      <span hidden = {true}  style={{ color: 'grey' , textAlign: "center", fontWeight: 'bold' , fontSize: '0.8vw' }} >APP: us({ustanding}) ureg({ureg}) utos({utos}) urdy({uready}) openGS({openGS}) </span> 
</Flex>





    </main>
    

  );
}

export default withAuthenticator(App, {
    initialState:tmpAuthState,
    hideSignUp:tmpHideSignUp,
    
    components: {
      
      Header,
      SignIn: {
        Header: SignInHeader,
        Footer: SignInFooter
      },
      SignUp: {
        Header: SignUpHeader,
        
      },
      Footer
    }
  });
